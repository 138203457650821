export class USGSOverlay extends google.maps.OverlayView {
  bounds: any;
  image: any;
  svg: any;
  div: any;
  color: string;
  item: any;
  isShowed = true;
  fill = false;
  strokeWidthState: number;
  constructor(bounds, image, svg, color?, item?) {
    super();
    // [END maps_overlay_hideshow_hideshowtoggle]
    this.color = color;
    this.bounds = bounds;
    this.image = image;
    this.svg = svg;
    this.item = item;
  }
  // tslint:disable: quotemark
  // [END maps_overlay_hideshow_subclass]
  // [START maps_overlay_hideshow_onadd]
  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  onAdd(): void {
    this.div = document.createElement('div');
    this.div.style.borderStyle = 'none';
    this.div.style.borderWidth = '0px';
    this.div.style.position = 'absolute';
    this.div.style.backgroundRepeat = 'no-repeat';
    if (this.svg) {
      this.div.style.zIndex = '99999';
      this.div.style.backgroundImage =
        "url('" +
        this.svg
          .replaceAll('rgb(255,255,0)', 'rgba(0,0,0,0)')
          // .replaceAll('stroke-width="1"', 'stroke-width="0px"')
          .replaceAll('opacity="1"', 'fill-opacity="0"') +
        "')";
    } else {
      if (
        this.item != null &&
        this.item.typeParcel != 'Единое землепользование' &&
        this.item.typeParcel != 'Контуры МКЗУ'
      ) {
         this.div.style.backgroundImage = "url('" + this.image + "')"; // FOR Image first parameters (xmin,ymin,xmax,ymax)
      }else if(this.item === null){
        this.div.style.backgroundImage = "url('" + this.image + "')";
      }

    }

    this.div.classList.add('somevoriclass'); //Todo delete
    this.div.style.backgroundSize = 'cover';
    this.div.style.backgroundPosition = 'center';
    // this.div.style.opacity = '0.7'; //Todo delete

    const panes = this.getPanes();
    panes.overlayLayer.appendChild(this.div);
  }

  // [END maps_overlay_hideshow_onadd]
  // [START maps_overlay_hideshow_draw]
  draw(): void {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    const overlayProjection = this.getProjection();
    // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We'll use these coordinates to resize the div.
    const sw = overlayProjection.fromLatLngToDivPixel(
      this.bounds.getSouthWest()
    );
    const ne = overlayProjection.fromLatLngToDivPixel(
      this.bounds.getNorthEast()
    );

    // Resize the image's div to fit the indicated dimensions.
    if (this.div) {
      this.div.style.left = sw.x + 'px';
      this.div.style.top = ne.y + 'px';
      this.div.style.width =
        (ne.x - sw.x < 0 ? -1 * (ne.x - sw.x) : ne.x - sw.x) + 'px';
      this.div.style.height =
        (sw.y - ne.y < 0 ? -1 * (sw.y - ne.y) : sw.y - ne.y) + 'px';
    }
  }

  onChangeBorderWidth(width): void {
    this.strokeWidthState = width
    // tslint:disable-next-line: no-unused-expression
    width <= 4 ? (width = 4) : null;
    if (this.div && this.svg) {
      if (this.fill) {
        this.div.style.backgroundImage =
          "url('" +
          this.svg
            .replaceAll(
              'rgb(255,255,0)',
              this.color ? this.color : 'rgb(255,255,0)'
            ) //todo delete replace
            .replaceAll('stroke-width="1"', 'stroke-width="' + width * 2 + '"')
            .replaceAll('opacity="1"', 'opacity="0.7"') +
          "')";
      } else {
        this.div.style.backgroundImage =
          "url('" +
          this.svg
            .replaceAll(
              'rgb(255,255,0)',
              this.color ? this.color : 'rgb(255,255,0)'
            )
            .replaceAll('stroke-width="1"', 'stroke-width="' + width * 2 + '"')
            .replaceAll('opacity="1"', 'fill-opacity="0"') +
          "')";
      }
    }
  }

  onFill(strokeWith = 30.65): void {

    if (this.svg) {
      if (this.fill) {
        this.div.style.backgroundImage =
          "url('" +
          this.svg
            .replaceAll(
              'rgb(255,255,0)',
              this.color ? this.color : 'rgb(255,255,0)'
            )
            .replaceAll('stroke-width="1"', `stroke-width="${this.strokeWidthState  * 2 }px"`)
            .replaceAll('opacity="1"', 'opacity="0.7"') +
          "')";
      } else {
        this.div.style.backgroundImage =
          "url('" +
          this.svg
            .replaceAll(
              'rgb(255,255,0)',
              this.color ? this.color : 'rgb(255,255,0)'
            )
            .replaceAll('stroke-width="1"', `stroke-width="${this.strokeWidthState  * 2 }px"`)
            .replaceAll('opacity="1"', 'fill-opacity="0"') +
          "')";
      }
    } else {
      this.div.style.backgroundImage = "url('" + this.image + "')"; // FOR Image first parameters (xmin,ymin,xmax,ymax)
    }
  }

  onRemove(): void {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      delete this.div;
    }
  }

  hide(): void {
    if (this.div) {
      this.div.style.visibility = 'hidden';
      this.isShowed = false;
    }
  }
  show(): void {
    if (this.div) {
      this.div.style.visibility = 'visible';

      this.isShowed = true;
    }
  }
  toggle(): void {
    if (this.div) {
      if (!this.isShowed) {
        this.show();
      } else {
        this.hide();
      }
    }
  }
  toggleDOM(map): void {
    if (this.getMap()) {
      this.setMap(null);
    } else {
      this.setMap(map);
    }
  }
}
