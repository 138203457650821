import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public showPass = false;
  public WronLoginPass = false;
  form = {
    clientName: '',
    password: '',
  };

  constructor(private Auth: AuthService) {}

  ngOnInit(): void {
    this.Auth.loginErrorListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.WronLoginPass = true;
      });
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.Auth.logIn(form.value);
    } else {
      this.WronLoginPass = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
