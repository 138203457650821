import {Injectable,EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Subject, Observable, BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CadastralService} from '../services/cadastral.service';

const BACKEND_URL = environment.appUrl;

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private cadastralService: CadastralService
  ) {
  }
  forReload:EventEmitter<any> = new EventEmitter()
  public tokenGeneratedPkks = [];
  public folderOptionEvent: Subject<string> = new Subject<string>();
  burgerOpen = false;
  private appUrls = {
    //userList
    getUserNames: 'client/get-client-names',
    // update client
    updateClient: 'client/update',
    // pkk
    getPkks: 'pkk/get',
    getPkksByRegion: 'pkk/get-by-region-id',
    get_by_access_token: 'pkk/get-by-access-token',
    generate_access_token: 'pkk/generate-access-token',
    // pkk  tag Type
    getTypes: 'pkk-tag-type/get-all',
    // pkk history
    gethistory: 'client-history/get',
    deleteHisdtoryItems: 'client-history/delete',
    // pkk folders
    getPkkFolderOptions: 'folder/get-pkk-folder-options',
    get_exist_pkks_on_folder: 'folder/get-exist-pkks-on-folder',
    shared_folders: 'folder/shared-folders',
    setFolderSeen: 'folder/set-is-view',
    getAllFolder: 'folder/get-all',
    addFolder: 'folder/add',
    deleteFolder: 'folder/delete',
    updateFolder: 'folder/update',
    // pkk metods inside  folder
    addToFolder: 'cadastral-folder/add',
    folderUpdate: 'cadastral-folder/update',
    deleteFromFolder: 'cadastral-folder/delete',
    moveToFolder: 'cadastral-folder/move',
    getfolderData: 'cadastral-folder/get-all',
    getWithoutImages: 'pkk/get-without-images',
    uploadFileForSearch: 'export/export-to-html',
    setNoImage: 'pkk/set-no-image',
  };
  private getHistorySubject = new BehaviorSubject<any>(null);

  private deleteHistorySubject = new BehaviorSubject<boolean>(null);
  private getFoldersSubject = new BehaviorSubject<any>(null);
  private getfolderDataSubject = new Subject<any>();
  public getCalcCordinatSubject = new BehaviorSubject<any>(null);


  public folders = [];
  public folderName = '';
  public pkkKeys = [];
  public pkkFolderKeys=[];
  public formData: FormData;
  public pkkTagTypes = [];

  async getpkk(CadNumbers: Array<any>): Promise<any> {
    return this.cadastralService.getPkk(CadNumbers);
  }

  async getpkkByLatLong(lat, lng): Promise<any> {
    return this.cadastralService.getPkksByLatLon(lat, lng);
  }

  public async getPkksByRegion(CadNumbers: Array<any>): Promise<any> {
    return this.cadastralService.getPkksByRegion(CadNumbers[0]);
  }

  getByAccessToken(sharedToken): Observable<any> {
    const data = {token: sharedToken};
    return this.httpClient.get(BACKEND_URL + this.appUrls.get_by_access_token, {
      params: data,
    });
  }

  generateAccessToken(data): Observable<any> {
    return this.httpClient.post(
      BACKEND_URL + this.appUrls.generate_access_token,
      data
    );
  }

  getPkkFolderOptions(data): Promise<any> {
    return this.httpClient.get(BACKEND_URL + this.appUrls.getPkkFolderOptions, {
      params: data,
    }).toPromise();
  }

  getUserNames(): Observable<any> {
    return this.httpClient.get(BACKEND_URL + this.appUrls.getUserNames);
  }

  updateClientEgrnKey(data): Observable<any> {
    return this.httpClient.post(BACKEND_URL + this.appUrls.updateClient, data);
  }

  public getExportedFolder(url: string, queries): Observable<any> {
    return this.httpClient.get(
      BACKEND_URL + url,
      { responseType: 'blob' as 'json', observe: 'response', params:  queries }
    );
  }

  getExistPkksOnFolder(data): Observable<any> {
    return this.httpClient.post(
      BACKEND_URL + this.appUrls.get_exist_pkks_on_folder,
      data
    );
  }

  giveFolterToUser(data): Observable<any> {
    return this.httpClient.post(
      BACKEND_URL + this.appUrls.shared_folders,
      data
    );
  }

  gethistory(filter): void {
    this.httpClient
      .get(BACKEND_URL + this.appUrls.gethistory, {params: filter})
      .subscribe((res) => {
        if (!res['hasError']) {
          this.getHistorySubject.next(res);
        }
      });
  }

  public getHistoryListener(): Observable<any> {
    return this.getHistorySubject.asObservable();
  }

  deleteItem(elementIds): void {
    this.httpClient
      .post(BACKEND_URL + this.appUrls.deleteHisdtoryItems, elementIds)
      .subscribe((res) => {
        if (res['data']) {
          this.deleteHistorySubject.next(res['data']);
        }
      });
  }

  public deleteHistoryListener(): Observable<boolean> {
    return this.deleteHistorySubject.asObservable();
  }

  getFolders(filter?): void {
    this.httpClient
      .get(BACKEND_URL + this.appUrls.getAllFolder, {params: filter})
      .subscribe((res) => {
        if (!res['hasError'] && res) {
          this.getFoldersSubject.next(res);
          this.folders = res['data']['entities'];
        }
      });
  }

  getFoldersSearch(filter): Promise<any> {
    return this.httpClient
      .get(BACKEND_URL + this.appUrls.getAllFolder, {
        params: filter,
      })
      .toPromise();
  }

  public getFoldersListener(): Observable<any> {
    return this.getFoldersSubject.asObservable();
  }

  addFolder(data): Promise<any> {
    return this.httpClient
      .post(BACKEND_URL + this.appUrls.addFolder, data)
      .toPromise();
  }

  deleteFolder(data): Promise<any> {
    return this.httpClient
      .post(BACKEND_URL + this.appUrls.deleteFolder, data)
      .toPromise();
  }

  updateFolder(data): Promise<any> {
    return this.httpClient
      .post(BACKEND_URL + this.appUrls.updateFolder, data)
      .toPromise();
  }

  getTypes(): Promise<any> {
    return this.httpClient.get(BACKEND_URL + this.appUrls.getTypes).toPromise();
  }

  updateCadFolderTags(data): Observable<any> {
    return this.httpClient.post(BACKEND_URL + this.appUrls.folderUpdate, data);
  }

  addToFolder(data): Observable<any> {
    return this.httpClient.post(BACKEND_URL + this.appUrls.addToFolder, data);
  }

  moveToFolder(data): Observable<any> {
    return this.httpClient.post(BACKEND_URL + this.appUrls.moveToFolder, data);
  }

  deleteFromFolder(data): Observable<any> {
    return this.httpClient.post(
      BACKEND_URL + this.appUrls.deleteFromFolder,
      data
    );
  }

  setFolderSeen(folderIds) {
    return this.httpClient
      .post(BACKEND_URL + this.appUrls.setFolderSeen, {ids: folderIds})
      .toPromise()
      .then((res) => {
      });
  }

  getfolderData(filter, id?): void {
    // let param = ;
    // id ? (param = '?FolderId=' + id) : '';

    this.httpClient
      .get(BACKEND_URL + this.appUrls.getfolderData, {
        params: {FolderId: id || '', ...filter},
      })
      .subscribe((res) => {
        if (!res['hasError']) {
          this.getfolderDataSubject.next(res);
        }
      });
  }

  getSinglefolderData(id): Promise<any> {
    return this.httpClient
      .get(BACKEND_URL + this.appUrls.getfolderData, {
        params: {FolderId: id},
      })
      .toPromise();
  }

  getWithoutImages(filter): Promise<any> {
    return this.httpClient
      .get(BACKEND_URL + this.appUrls.getWithoutImages, {params: filter})
      .toPromise();
  }

  setNoImage(filter: any[]): Promise<any> {
    return this.httpClient
      .post(BACKEND_URL + this.appUrls.setNoImage, filter)
      .toPromise();
  }

  public uploadFileForSearch(file): Observable<any> {
    return this.httpClient.post(BACKEND_URL + this.appUrls.uploadFileForSearch, file);
  }

  public getfolderDataListener(): Observable<any> {
    return this.getfolderDataSubject.asObservable();
  }
}
