import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { HomeService } from '../../home.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() closepopup = new EventEmitter<string>();
  @Output() MoveEvent = new EventEmitter<any>();
  public folderName = 'Новая папка';
  selectedFolderId: any;
  constructor(private homeService: HomeService) {}
  ngOnInit(): void {
  }

  closePopup(): void {
    this.closepopup.emit();
  }

  CreateFolder(): void {
    // this.homeService
    //   .addFolder({ name: this.folderName })
    //   .then((res) => {
    //     this.homeService.getFolders();
    //     this.closepopup.emit();
    //   });
        this.homeService
          .addFolder({ name: this.folderName })
          .then((res) => {
            this.selectedFolderId = res.data.id;
            this.folderName = res.data.name;
            this.homeService.getFolders();
            this.MoveEvent.emit({
              id: this.selectedFolderId,
              name: this.folderName,
            });
            this.closepopup.emit();
          });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
