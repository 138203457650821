<div class="popup">
  <div class="popup-inner">
    <button class="link w-30 close" (click)="close()">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.05078 2.05025L11.9503 11.9498" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M2.05078 11.9498L11.9503 2.05026" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </button>
    <p class="block-title">Переместить выбранное в папку</p>
    <div class="for-scroll">
      <div class="pop-folders">
        <ul class="folders-list">
          <li class="to-folder" (click)="selectedFolderId = 0;folderName = 'Папки'"
            [class.active]="0 == selectedFolderId">
            <a>
              <p>Папки</p>
            </a>
          </li>
          <li>
            <a (click)="newFolderMode = true">
              <img src="../../../../assets/images/folder-empty-icon.svg" alt="folder">
              <input class="newFolder" [readonly]="!newFolderMode" [(ngModel)]="newFolder"
                (click)="newFolder === 'Новая папка' ? newFolder = '':null" />
            </a>
          </li>
          <li *ngFor="let folder of folders" (click)="selectedFolderId =folder.folder.id;folderName = folder.folder.name"
            [class.active]="folder.folder.id == selectedFolderId">
            <a>
              <img src="../../../../assets/images/folder-icon.svg" alt="folder">
              <p>{{folder.folder.name}}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <button class="blue-button" (click)="onMove()" [disabled]="selectedFolderId == null && (!newFolderMode || !newFolder)" >Переместить</button>
  </div>
</div>
