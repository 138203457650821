import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomResModel } from '../shared/models/ICustom.model';
import { LoginModel, LoginResModel } from '../shared/models/login.model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SelectedPlotsService } from '../pages/selected-plots/selected-plots.service';

const BACKEND_URL = environment.appUrl;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  hasAccessToLot: boolean;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dbService: NgxIndexedDBService,
    private selectedPlotsService: SelectedPlotsService
  ) {}
  public LoginEvent = new Subject<boolean>();
  private token: string;
  private clientId: number;
  private userName: string;
  private clientName: string;
  private clientEgrnKey: string;
  private roleName: string;
  private isAuthenticated = false;
  private userAuthenSubject = new Subject<boolean>();
  private loginErrorSubject = new Subject<any>();
  private appUrls = {
    login: 'client/login',
    logout: 'User/LogOut',
  };

  public getIsAuth(): boolean {
    return this.isAuth();
  }

  public isAuth(): boolean {
    const isAuth = this.getAuthData();
    if (isAuth) {
      this.token = isAuth.token;
      this.clientId = Number(isAuth.clientId);
      this.isAuthenticated = true;
      this.userAuthenSubject.next(true);
      return true;
    }
  }
  logIn(data: LoginModel): void {
    this.httpClient
      .post<CustomResModel<LoginResModel>>(
        BACKEND_URL + this.appUrls.login,
        data
      )
      .subscribe(
        (response) => {
          if (response.hasError) {
            this.loginErrorSubject.next(response);
            this.userAuthenSubject.next(false);
            return;
          }
          this.token = response.data.token;
          this.clientId = response.data.clientId;
          this.userName = response.data.client.name;
          this.clientName = response.data.client.clientName;
          this.clientEgrnKey = response.data.client?.egrnKey?.key;
          this.hasAccessToLot = response.data.client.hasAccessToLot;
          if (this.token) {
            this.isAuthenticated = true;
            this.userAuthenSubject.next(true);
            this.LoginEvent.next(true);
            this.saveInLocalStorage(
              this.token,
              this.clientId,
              this.userName,
              this.clientName,
              this.clientEgrnKey,
              this.hasAccessToLot
            );
            this.router.navigate(['/CadSearch']);
          }
        },
        (error) => {
          this.userAuthenSubject.next(false);
          this.loginErrorSubject.next(error);
        }
      );
  }
  private saveInLocalStorage(
    token: string,
    clientId: number,
    userName: string,
    clientName: string,
    clientEgrnKey: string,
    hasAccessToLot: boolean
  ): void {
    localStorage.setItem('token', token);
    localStorage.setItem('userId', clientId.toString());
    localStorage.setItem('userName', userName.toString());
    localStorage.setItem('clientName', clientName.toString());
    localStorage.setItem('clientEgrnKey', clientEgrnKey ? clientEgrnKey.toString() : "");
    localStorage.setItem('hasAccessToLot', hasAccessToLot.toString());
  }

  private getAuthData(): { token: string; clientId: string } {
    const token = localStorage.getItem('token');
    const clientId = localStorage.getItem('userId');

    if (!token && !clientId) {
      return;
    }
    return { token, clientId };
  }
  public loginErrorListener(): Observable<any> {
    return this.loginErrorSubject.asObservable();
  }
  public getToken(): string {
    return this.token;
  }
  public logoutUserOffline(): void {
    this.token = null;
    this.isAuthenticated = false;
    this.userAuthenSubject.next(false);
    this.removeFromLocalStorage();
    this.clearDatabase();
    this.router.navigate(['/login']);
  }
  public removeFromLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('clientEgrnKey');
  }

  public clearDatabase() {
    this.dbService.clear('cadItems').subscribe((successDeleted) => {});
    this.selectedPlotsService.plotSubject.next([]);
  }
}
