<div class="popup">
  <div class="popup-inner">
    <svg class="close" (click)="close()" width="30" height="30" viewBox="0 0 30 30" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="30" height="30" rx="3" fill="#F3F3F3" />
    <path d="M10.0508 10.0503L19.9503 19.9498" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M10.0508 19.9497L19.9503 10.0502" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
    <div class="info"> Переместить выбранное в папку</div>
    Папка «{{folderName}}» уже содержит номера, которые вы выбрали для перемещения. Данные номера будут заменены на
    актуальные
    <div class="action-buttons">
      <button class="blue-button" (click)="confirm()">Подтвердить</button>
    </div>
  </div>
</div>
