import {Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import html2canvas from 'html2canvas';
import {BehaviorSubject, Subject} from 'rxjs';
import {ICadItems} from 'src/app/shared/models/ICadItems';
import {HomeService} from '../home.service';
import * as saveAs from 'file-saver';
import {TileHelper} from '../../../../tile-helper';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {USGSOverlay} from '../map/map-overlay';
import {IitemMove} from 'src/app/shared/models/IFolderModel';
import {CustomResModel} from 'src/app/shared/models/ICustom.model';
import {colors} from 'src/app/shared/colors.constants';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {CadastralService} from 'src/app/services/cadastral.service';

declare var ymaps: any; // Yandex Maps
declare var ol: any; // Open Layers (OpenStreetMap)

@Component({
  selector: 'app-token-link',
  templateUrl: './token-link.component.html',
  styleUrls: ['./token-link.component.scss'],
})
export class TokenLinkComponent implements OnInit {
  token = '';
  pkks: any;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('mymap', {static: false}) map: any;
  @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;
  public mapHost = mapHost;
  public currentMap: string = mapHost.Google;
  public prevMapCenter: any;
  public prevMapZoom: any;
  public yandexMap: any;
  public openStreetMap: any;
  public currentPosition: any;
  public subject = new BehaviorSubject(null);
  public showDublicatePopup: boolean;
  public cadSearch = '';
  public zoomEvent = new Subject();
  public toaster = false;
  public showLoading = false;
  public center: any;
  public mapTypeId = 'hybrid';
  public div: any;
  public cadItems: ICadItems[] = [];
  public selecdetPrice = 0;
  public scuare = 0;
  public selectedCadItems = [];
  public KeyList = '';
  public toFilderName = [];
  public divergencePopup = false as any;
  public link = '';
  public tagTypes = [{name: 'Все', id: 0}] as any;
  public geometryCalcs: { distance: number | string, area: number | string } = {
    distance: 0, area: 0,
  };
  public colors = colors;
  tempdata;
  options: google.maps.MapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };
  public itemMovePopup: boolean;
  public createFolderPopup: boolean;
  public polyLines = [];

  public tileHelper: TileHelper;
  redLinesOverlays = [];
  public redLinesEnabled = false;
  isSubscribedToBoundChange = false;
  mapTypeDropdownIsOpened: boolean;
  public cadCenterPositions: any = [];
  public centerMarkersEnabled = false;
  public rightBarOpen: boolean;

  mapTypeName = 'Гибрид';
  drawingManager: google.maps.drawing.DrawingManager;
  cadInfoOnMap: any;

  private isAllCadItemsLoaded = false;
  private isAllComputed = false;
  private isDrawnByCoords = false;
  private isDrawnSVG = false;
  private tempColor: any;

  public layerTypes = LayerTypes;
  public layers = [{
    type: LayerTypes.Land,
    isEnabled: false,
    overlays: []
  },
    {
      type: LayerTypes.Border,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Zone,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Territory,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.SpecialZone,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Building,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Union,
      isEnabled: false,
      overlays: []
    }];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    public homeService: HomeService,
    public cad: CadastralService
  ) {
    this.tileHelper = new TileHelper();

    this.activatedRoute.params.subscribe((param) => {
      this.token = param.token;
      if (
        localStorage.getItem('token') &&
        localStorage.getItem('userId') &&
        localStorage.getItem('userName')
      ) {
        this.homeService
          .getByAccessToken(this.token)
          .subscribe((res: CustomResModel<any>) => {
            this.homeService.tokenGeneratedPkks = res.data;
            const pkks = res.data.map(x => {
              return {
                keys: x.pkk.key
              };
            });
            sessionStorage.setItem('pkks', JSON.stringify(pkks));
            this.router.navigateByUrl('/map');
          });
      } else {
        this.homeService.getByAccessToken(this.token).subscribe((res) => {
          res.data.forEach((element) => {
            element.cadItem = element.pkk;
            element.pkkTagTypeId
              ? (element.cadItem.pkkTagTypeId = element.pkkTagTypeId)
              : null;
            element.shareInTheRight
              ? (element.cadItem.shareInTheRight = element.shareInTheRight)
              : null;
            element.colorAreaId
              ? (element.cadItem.colorAreaId = element.colorAreaId)
              : null;
          });

          res.data.map((element) => {
            const childItems = element.childPKKs.map(x => {
              return {
                cadItem: x,
                overlay: null,
                bound: null,
                checked: true,
              };
            });
            return this.cadItems.push({
              cadItem: element.pkk,
              overlay: null,
              bound: null,
              checked: true,
              childItems,
            });
          });

          this.fillDataFromMap();
          this.cadItems?.forEach(x => this.fillChildDataFromMap(x));
          this.sumAmionth();
        });
      }
    });
  }

  ngOnInit(): void {
    this.getTagTypes();
    this.zoomEvent
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((e) => {
          if (!this.isSubscribedToBoundChange) {
            // this.map.googleMap.addListener('dragend', () => this.loadRedLines());
            // this.map.googleMap.addListener('zoom_changed', () => this.loadRedLines());
            this.map.googleMap.addListener('tilesloaded', () =>
              this.loadTilesAdditionData()
            );
            this.isSubscribedToBoundChange = true;
          }
          this.cadItems.forEach((el) => {
            el.overlay?.onChangeBorderWidth(e);
            if (el.childItems.length) {
              el.childItems.forEach((el) => {
                el.overlay?.onChangeBorderWidth(e);
              });
            }
          });
        })
      )
      .subscribe();
  }

  getTagTypes(): void {
    this.homeService.getTypes().then((res) => {
      this.homeService.pkkTagTypes = res.data;
      this.tagTypes = [
        ...this.tagTypes,
        ...this.homeService.pkkTagTypes.reverse(),
      ];
    });
  }

  public removeDrawings(): void {
    this.polyLines.forEach(element => {
      element.setMap(null);
    });

    this.geometryCalcs = {
      distance: 0,
      area: 0
    };

    this.drawingManager.setMap(null);
  }

  public toggleBurger() {
    this.homeService.burgerOpen = !this.homeService.burgerOpen;

    if (this.currentMap == mapHost.OpenStreet) {
      setTimeout(() => {
        this.openStreetMap.updateSize();
      }, 500);
    }
  }

  public addPolyline(event): void {
    if (event.target.checked) {
      this.loadMapDrawingTools();
    } else if (!event.target.checked) {
      this.removeDrawings();
    }
  }

  private loadMapDrawingTools(): void {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYLINE,
        ],
      },
      circleOptions: {
        fillColor: '#ffff00',
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
      polylineOptions: {
        clickable: true,
        editable: true,
      },
    });
    this.drawingManager.setMap(this.map.googleMap);
    this.drawingManager.addListener('polylinecomplete', (event) => {
      const distance = google.maps.geometry.spherical.computeLength(event.getPath().getArray()) / 1000;
      const area = google.maps.geometry.spherical.computeArea(event.getPath().getArray()) / 1000000;
      this.geometryCalcs.distance = distance.toFixed(3);
      this.geometryCalcs.area = area.toFixed(3);
      this.polyLines.push(event);
      event.addListener('mouseup', (e) => {
        const editedDistance = google.maps.geometry.spherical.computeLength(event.getPath().getArray()) / 1000;
        const editedArea = google.maps.geometry.spherical.computeArea(event.getPath().getArray()) / 1000000;
        this.geometryCalcs.distance = editedDistance.toFixed(3);
        this.geometryCalcs.area = editedArea.toFixed(3);
      });
    });
  }

  parentCheched(parentId, child, event): void {
    child.checked = event.target.checked;

    const childPos = this.cadCenterPositions.find(x => x.key == child.cadItem.key);
    if (childPos) {
      childPos.isSelected = event.target.checked;
    }

    let checkParent = true;
    const parent = this.cadItems.find((el) => el.cadItem.id == parentId);
    parent.childItems?.forEach((element) => {
      if (!element.checked) {
        checkParent = false;
      }
    });
    parent.checked = checkParent;
    this.sumAmionth();
  }

  goToCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.currentPosition = {
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            // not used
            // label: {
            //   color: 'red',
            //   text: 'Marker label ',
            // },
            // title: 'Marker title ',
            options: {animation: google.maps.Animation.DROP, icon: '/assets/images/google-maps-circle-icon.png'},
          };

          if (this.currentMap == mapHost.Google) {
            this.map.googleMap.setCenter(pos);
          }

          if (this.currentMap == mapHost.Yandex) {
            const coordinates = [position.coords.latitude, position.coords.longitude];

            this.yandexMap.geoObjects.add(new ymaps.Placemark(coordinates, {}, {
              iconLayout: 'default#image',
              iconImageHref: '/assets/images/google-maps-circle-icon.png',
              iconImageSize: [32, 32],
            }));
            this.yandexMap.setCenter(coordinates);
          }

          if (this.currentMap == mapHost.OpenStreet) {
            const coords = ol.proj.fromLonLat([position.coords.longitude, position.coords.latitude]);

            const iconFeature = new ol.Feature({geometry: new ol.geom.Point(coords)});

            iconFeature.set('style', new ol.style.Style({
              image: new ol.style.Icon({
                src: '/assets/images/google-maps-circle-icon.png',
                imgSize: [32, 32]
              }),
            }));

            this.openStreetMap.addLayer(
              new ol.layer.Vector({
                style(feature) {
                  return feature.get('style');
                },
                source: new ol.source.Vector({features: [iconFeature]}),
              })
            );
            this.openStreetMap.getView().setCenter(coords);
          }
        },
        () => {
          alert('Error: The Geolocation service failed.');
        }
      );
    } else {
      alert('Error: Your browser doesn\'t support geolocation.');
    }
  }

  moveToCurrentCenter() {
    if (this.currentMap == mapHost.Google) {
      this.map.googleMap.setCenter({
        lat: this.prevMapCenter[0],
        lng: this.prevMapCenter[1]
      });
      this.map.googleMap.setZoom(this.prevMapZoom);
      return;
    }
    if (this.currentMap == mapHost.Yandex) {
      const coords = this.prevMapCenter[0] ? [this.prevMapCenter[0], this.prevMapCenter[1]]
        : [this.prevMapCenter.lat(), this.prevMapCenter.lng()];
      this.yandexMap.setCenter(coords);
      this.yandexMap.setZoom(this.prevMapZoom);
    }
    if (this.currentMap == mapHost.OpenStreet) {
      const lonLat = this.prevMapCenter[0] ? [this.prevMapCenter[1], this.prevMapCenter[0]]
        : [this.prevMapCenter.lng(), this.prevMapCenter.lat()];
      this.openStreetMap.getView().setCenter(ol.proj.fromLonLat(lonLat));
      this.openStreetMap.getView().setZoom(this.prevMapZoom);
    }
  }


  fillChildDataFromMap(item): void {
    if (this.map?.googleMap || this.yandexMap || this.openStreetMap) {
      this.tempColor = this.getColor(item, true);
      item.childItems.map((child) => {
        if (child.checked) {
          if (child.cadItem.cordinats && !this.isDrawnSVG) {
            // Coordinates stores in server as JSON
            JSON.parse(child.cadItem.cordinats)
              .forEach(coords => this.drawPolyLinesByLatLang(coords, child));
            this.isDrawnByCoords = true;
          } else {
            this.drawSVGforElement(child, this.tempColor);
            this.isDrawnSVG = child.cadItem.imageSvg ? true : false;
          }
        }
      });
      if (this.isDrawnSVG) {
        this.isDrawnByCoords = false;
      }
      if (this.isDrawnByCoords) {
        this.moveToElement();
      }
    }
  }

  fillDataFromMap(): void {
    if (this.cadItems && (this.map?.googleMap || this.yandexMap || this.openStreetMap)) {
      this.cadItems.forEach((element) => {
        if (element.checked) {
          this.tempColor = this.getColor(element);
          if (element.cadItem.cordinats && !this.isDrawnSVG) {
            // Coordinates stores in server as JSON
            if (element.cadItem.typeParcel != 'Контуры МКЗУ') {
              JSON.parse(element.cadItem.cordinats)
                .forEach(coords => this.drawPolyLinesByLatLang(coords, element));
              this.isDrawnByCoords = true;
            }
          } else {
            this.drawSVGforElement(element, this.tempColor);
            this.isDrawnSVG = element.cadItem.imageSvg ? true : false;
          }
        }
      });
      this.changeDetectorRef.detectChanges();
      if (this.isDrawnSVG) {
        this.isDrawnByCoords = false;
      }
      if (this.isDrawnByCoords) {
        this.moveToElement();
      }
    }
  }


  drawSVGforElement(element: ICadItems, rgbColor) {
    const elementMins = this.meters2degress(
      element.cadItem.xmin,
      element.cadItem.ymin
    );
    const elementmaxs = this.meters2degress(
      element.cadItem.xmax,
      element.cadItem.ymax
    );
    const elementBound = new google.maps.LatLngBounds(
      new google.maps.LatLng(elementMins[1], elementMins[0]),
      new google.maps.LatLng(elementmaxs[1], elementmaxs[0])
    );
    this.map.fitBounds(elementBound);
    const elementOverlay = this.drawOverlay(
      elementBound,
      element.cadItem.imageBase64
        ? 'data:image/png;base64,' + element.cadItem.imageBase64
        : '',
      element.cadItem.imageSvg
        ? 'data:image/svg+xml;utf8,' + element.cadItem.imageSvg
        : null,
      rgbColor,
      element.cadItem
    );
    element.bound = elementBound;
    element.overlay = elementOverlay;
  }

  getColor(element: ICadItems, isChild: boolean = false): string {
    if (isChild) {
      return this.colors.find(
        (x) => x.id == element.cadItem.colorAreaId
      )?.rgb;
    }
    return element.cadItem.colorAreaId
      ? this.colors[element.cadItem.colorAreaId - 1].rgb
      : null;
  }

  onTileLoaded() {
    if ((this.isAllComputed || this.isDrawnByCoords) && this.isAllCadItemsLoaded) {
      return;
    }

    if (this.isAllCadItemsLoaded) {
      // if cadItem or childCadItem doesn't have computed coordinates yet
      // compute them and send to server as JSON
      this.cadItems.forEach(item => {
        if (!item.cadItem.cordinats && item.overlay?.div && item.cadItem.imageSvg) {
          const coordinates = JSON.stringify(this.computeSVGPathsCoords(item));

          this.cad.updateCoordinates({
            key: item.cadItem.key,
            cordinats: coordinates
          });

          item.cadItem.cordinats = coordinates;
        }

        if (item.childItems.length) {
          item.childItems.forEach(childItem => {
            if (!childItem.cadItem.cordinats && childItem.overlay.div && childItem.cadItem.imageSvg) {
              const childCoordinates = JSON.stringify(this.computeSVGPathsCoords(childItem));

              this.cad.updateCoordinates({
                key: childItem.cadItem.key,
                cordinats: childCoordinates
              });

              childItem.cadItem.cordinats = childCoordinates;
            }
          });
        }
      });
      this.isAllComputed = true;
    }
  }

  computeSVGPathsCoords(item: ICadItems): LatLng[][] {
    const svg = item.cadItem.imageSvg;

    const svgSize = this.getSVGSizes(svg);
    const svgPaths = this.getSVGPaths(svg);

    const scaleX = item.overlay.div.clientWidth / svgSize.width;
    const scaleY = item.overlay.div.clientHeight / svgSize.height;

    const svgCoords = this.getSVGCoordsByPaths(svgPaths);

    let scale;
    const computedSvgCoords = svgCoords.map(points => {
      if (!points.isChild) {
        scale = points.coords[1] == 0 ? scaleY : scaleX;
      }
      points.coords = points.coords.map(point => point * scale);
      return points;
    });

    return this.getPolylineLatLngBySVGCoords(computedSvgCoords, item.overlay);
  }

  getSVGSizes(svg: string): { width: number, height: number } {
    const svgWidthIndex = svg.indexOf('width=\"') + 7;
    const svgHeighthIndex = svg.indexOf('height=\"') + 8;

    return {
      width: +svg.slice(svgWidthIndex, svg.indexOf('\"', svgWidthIndex)),
      height: +svg.slice(svgHeighthIndex, svg.indexOf('\"', svgHeighthIndex))
    };
  }

  getSVGPaths(svg: string): string[] {
    const svgPaths = [];
    svg.split('<path').forEach((path, index) => {
      if (index) {
        const svgPathIndex = path.indexOf('d=\"') + 3;
        const svgPath = path.slice(svgPathIndex, path.indexOf('\"', svgPathIndex));

        svgPaths.push(svgPath);
      }
    });
    return svgPaths;
  }

  getSVGCoordsByPaths(paths: string[]): { coords: number[], isChild: boolean }[] {
    const coordinates = [];
    paths.forEach(path => {
      path.split('Z M').forEach((splitedPath, index) => {
        coordinates.push({
          coords: splitedPath.match(/(\d+\.\d*)|(\d+)/g),
          isChild: index ? true : false
        });
      });
    });

    return coordinates;
  }

  getMinimalPoints(array: number[]): { x: number, y: number } {
    const minimalPoint: any = {
      x: array[0],
      y: array[1],
    };
    for (let i = 0; i < array.length - 1; i += 2) {
      if (minimalPoint.x > array[i]) {
        minimalPoint.x = array[i];
      }
    }
    for (let i = 1; i < array.length - 1; i += 2) {
      if (minimalPoint.y > array[i]) {
        minimalPoint.y = array[i];
      }
    }
    return minimalPoint;
  }

  getPolylineLatLngBySVGCoords(svgCoords: { coords: number[], isChild: boolean }[], overlay: USGSOverlay): LatLng[][] {
    const overlayProjection = overlay.getProjection();
    const leftOffset = parseFloat(overlay.div.style.left);
    const topOffset = parseFloat(overlay.div.style.top);

    const figures = [];
    let minCoord;

    svgCoords.forEach(svgCoord => {
      if (!svgCoord.isChild) {
        minCoord = this.getMinimalPoints(svgCoord.coords);
      }

      const polyCoordinates: LatLng[] = [];

      // Even - X coorditane, Odd - Y coordinate
      for (let i = 0; i < svgCoord.coords.length - 1; i += 2) {
        const coord: google.maps.LatLng = overlayProjection.fromDivPixelToLatLng(
          new google.maps.Point(
            svgCoord.coords[i] - minCoord.x + leftOffset,
            svgCoord.coords[i + 1] - minCoord.y + topOffset
          )
        );
        polyCoordinates.push({
          lat: coord.lat(),
          lng: coord.lng()
        });
      }
      figures.push(polyCoordinates);
    });
    return figures;
  }

  drawPolyLinesByLatLang(polyCoords: LatLng[], item: ICadItems): void {
    if (this.isDrawnSVG) {
      return;
    }
    if (!item.drawedObjects) {
      item.drawedObjects = [];
    }

    if (this.currentMap == mapHost.Google) {
      item.drawedObjects.push(
        this.drawPolylineOnGoogleMap(polyCoords)
      );
      return;
    }

    if (this.currentMap == mapHost.Yandex) {
      item.drawedObjects.push(
        this.drawPolylineOnYandexMap(polyCoords.map(coord => [coord.lat, coord.lng]))
      );
      return;
    }

    if (this.currentMap == mapHost.OpenStreet) {
      item.drawedObjects.push(
        this.drawLineOnOpenStreetMap(
          polyCoords.map(coord => ol.proj.fromLonLat([coord.lng, coord.lat]))
        )
      );
      return;
    }
  }

  drawPolylineOnGoogleMap(polyCoords: LatLng[]): google.maps.Polyline {
    const polylines = new google.maps.Polyline({
      path: polyCoords,
      strokeColor: this.tempColor || 'rgb(255, 255, 0)', // yellow
      strokeOpacity: 1,
      strokeWeight: 2,
    });

    polylines.setMap(this.map.googleMap);
    return polylines;
  }

  /**returns ymaps.Polyline */
  drawPolylineOnYandexMap(polyCoords: number[][]) {
    const polyline = new ymaps.Polyline(polyCoords, {}, {
      strokeColor: this.tempColor || 'rgb(255, 255, 0)', // yellow
      strokeWidth: 2,
      strokeOpacity: 1,
    });
    this.yandexMap.geoObjects.add(polyline);
    return polyline;
  }

  /**returns ol.layer.Vector */
  drawLineOnOpenStreetMap(lineCoords: number[][]) {
    const featureLine = new ol.Feature({
      geometry: new ol.geom.LineString(lineCoords)
    });
    const vectorLine = new ol.source.Vector({});
    vectorLine.addFeature(featureLine);

    const vectorLineLayer = new ol.layer.Vector({
      source: vectorLine,
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: this.tempColor || 'rgb(255, 255, 0)', // yellow
          width: 2
        })
      })
    });

    this.openStreetMap.addLayer(vectorLineLayer);
    return vectorLineLayer;
  }

  redrawPolyLinesOnMap(): void {
    this.isDrawnSVG = false;
    // Coordinates stores in server as JSON
    this.cadItems.forEach(item => {
      item.drawedObjects = [];
      if (item.cadItem.cordinats && item.cadItem.typeParcel != 'Контуры МКЗУ') {
        this.tempColor = this.getColor(item);
        JSON.parse(item.cadItem.cordinats)
          .forEach(coords => this.drawPolyLinesByLatLang(coords, item));
      }
      if (item.childItems.length) {
        item.childItems.forEach(childItem => {
          childItem.drawedObjects = [];
          if (childItem.cadItem.cordinats) {
            this.tempColor = this.getColor(item, true);
            JSON.parse(childItem.cadItem.cordinats)
              .forEach(coords => this.drawPolyLinesByLatLang(coords, childItem));
          }
        });
      }
    });
    this.isDrawnByCoords = true;
    this.moveToCurrentCenter();
  }

  async mapChange() {
    this.prevMapCenter = this.map?.googleMap.getCenter() ||
      this.yandexMap.getCenter() ||
      this.openStreetMap.getCenter();
    this.prevMapZoom = this.map?.googleMap.getZoom() ||
      this.yandexMap.getZoom() ||
      this.openStreetMap.getZoom();

    if (this.currentMap == mapHost.Google) {
      // for update ViewChild
      // todo: check this
      // this.isSubscribedToBoundChange = false;
      // this.addMainZoomEventOnGoogleMaps();
      this.changeDetectorRef.detectChanges();
      this.redrawPolyLinesOnMap();
    }

    if (this.currentMap == mapHost.Yandex) {
      await ymaps.ready();
      this.yandexMap = new ymaps.Map('yandexMap', {
          zoom: 12,
          center: [55.75490171455126, 37.62086691244819],
          controls: [
            new ymaps.control.ZoomControl({
              options: {
                size: 'small',
                position: {bottom: 50, right: 20}
              }
            })
          ]
        },
        {autoFitToViewport: 'always'});
      this.setYandexMapType();

      this.redrawPolyLinesOnMap();
    }

    if (this.currentMap == mapHost.OpenStreet) {
      setTimeout(() => {
        this.openStreetMap = new ol.Map({
          target: 'openStreetMap',
          layers: [
            new ol.layer.Tile({
              source: new ol.source.OSM({
                url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              })
            }),
            new ol.layer.Tile({
              visible: false,
              source: new ol.source.BingMaps({
                key: 'Alyb_nxW-1g2r31Mgjnx_uPMmaRIwJCUBJK3jtcYTbfR2WnpvHBVZNBdA3j915B0',
                imagerySet: 'Aerial'
              })
            }),
            new ol.layer.Tile({
              visible: false,
              source: new ol.source.BingMaps({
                key: 'Alyb_nxW-1g2r31Mgjnx_uPMmaRIwJCUBJK3jtcYTbfR2WnpvHBVZNBdA3j915B0',
                imagerySet: 'AerialWithLabelsOnDemand'
              })
            }),
          ],
          view: new ol.View({
            center: ol.proj.fromLonLat([37.62086691244819, 55.75490171455126]),
            zoom: 12
          })
        });
        this.setOpenStreetMapType();
        this.redrawPolyLinesOnMap();
      });
    }
  }


  viewChange(type: string, name: string): void {
    this.mapTypeId = type;
    this.mapTypeName = name;
    this.maptypeDropdownHandler();

    if (this.currentMap == mapHost.Yandex) {
      this.setYandexMapType();
    }
    if (this.currentMap == mapHost.OpenStreet) {
      this.setOpenStreetMapType();
    }
  }

  setYandexMapType() {
    this.yandexMap.setType(`yandex#${this.mapTypeId == 'roadmap' ? 'map' : this.mapTypeId}`);
  }

  setOpenStreetMapType() {
    let selectedTypeIndex;
    if (this.mapTypeId == 'roadmap') {
      selectedTypeIndex = 0;
    }
    if (this.mapTypeId == 'satellite') {
      selectedTypeIndex = 1;
    }
    if (this.mapTypeId == 'hybrid') {
      selectedTypeIndex = 2;
    }

    const layers = this.openStreetMap.getAllLayers();
    for (let i = 0; i < 3; i++) {
      layers[i].setVisible(i == selectedTypeIndex);
    }
  }


  removeOverlay(item): void {
    this.isDrawnSVG ? item.overlay.onRemove() : this.deleteItemFromMap(item);
    if (item.childItems.length) {
      item.childItems.forEach((element) => {
        this.isDrawnSVG ? element.overlay.onRemove() : this.deleteItemFromMap(element);
      });
    }
  }

  deleteItemFromMap(item: ICadItems) {
    switch (this.currentMap) {
      case mapHost.Google:
        this.toggleOnGoogle(item, false);
        break;
      case mapHost.Yandex:
        this.toggleOnYandex(item, false);
        break;
      case mapHost.OpenStreet:
        this.toggleOnOpenStreet(item, false);
        break;
    }
    item.drawedObjects = [];
  }

  sumAmionth(): void {
    this.selecdetPrice = 0;
    this.scuare = 0;
    if (this.cadItems.length) {
      this.cadItems.forEach((el) => {
        if (el.checked && el.cadItem.typeParcel !== 'Единое землепользование' && el.cadItem.typeParcel !== 'Контуры МКЗУ') {
          if (el.cadItem.shareInTheRight) {
            const shareRight = el.cadItem.shareInTheRight.split('/');

            const tempNumber =
              (el.cadItem.cadastralCost * +shareRight[0]) /
              +shareRight[1];
            this.selecdetPrice += tempNumber;

            const tempArea = (el.cadItem.areaValue * +shareRight[0]) /
              +shareRight[1];
            this.scuare += tempArea;

          } else {
            this.selecdetPrice += el.cadItem.cadastralCost;
            this.scuare += el.cadItem.areaValue;
          }
        } else if (
          el.checked &&
          (el.cadItem.typeParcel === 'Единое землепользование' || el.cadItem.typeParcel === 'Контуры МКЗУ') &&
          !el.childItems.length
        ) {

          if (el.cadItem.shareInTheRight) {
            const shareRight = el.cadItem.shareInTheRight.split('/');

            const tempNumber =
              (el.cadItem.cadastralCost * +shareRight[0]) /
              +shareRight[1];
            this.selecdetPrice += tempNumber;

            const tempArea = (el.cadItem.areaValue * +shareRight[0]) /
              +shareRight[1];
            this.scuare += tempArea;
          } else {

            this.selecdetPrice += el.cadItem.cadastralCost;
            this.scuare += el.cadItem.areaValue;
          }

        } else {
          el.childItems.forEach((child) => {
            if (child.checked) {
              if (el.cadItem.shareInTheRight) {
                const shareRight = el.cadItem.shareInTheRight.split('/');

                const tempNumber =
                  (child.cadItem.cadastralCost * +shareRight[0]) /
                  +shareRight[1];
                this.selecdetPrice += tempNumber;

                const tempArea = (child.cadItem.areaValue * +shareRight[0]) /
                  +shareRight[1];
                this.scuare += tempArea;
              } else {
                this.selecdetPrice += child.cadItem.cadastralCost;
                this.scuare += child.cadItem.areaValue;
              }
            }
          });
        }
      });
    }
  }

  toggleChilds(parent, event): void {
    const parentPos = this.cadCenterPositions.find(x => x.key == parent.cadItem.key);
    if (parentPos) {
      parentPos.isSelected = event.target.checked;
    }

    parent.childItems.forEach((child) => {
      if (this.isDrawnSVG && child.overlay) {
        event.target.checked ? child.overlay.show() : child.overlay.hide();
      }

      if (this.isDrawnByCoords) {
        switch (this.currentMap) {
          case mapHost.Google:
            this.toggleOnGoogle(child, event.target.checked);
            break;
          case mapHost.Yandex:
            this.toggleOnYandex(child, event.target.checked);
            break;
          case mapHost.OpenStreet:
            this.toggleOnOpenStreet(child, event.target.checked);
            break;
        }
      }
      child.checked = event.target.checked;
      const childPos = this.cadCenterPositions.find(x => x.key == child.cadItem.key);
      if (childPos) {
        childPos.isSelected = event.target.checked;
      }
    });
    this.sumAmionth();
  }

  toggleChildItem(item, $event) {
    if (this.isDrawnSVG) {
      item.overlay.toggle();
    }

    if (this.isDrawnByCoords) {
      switch (this.currentMap) {
        case mapHost.Google:
          this.toggleOnGoogle(item, $event.target.checked);
          break;
        case mapHost.Yandex:
          this.toggleOnYandex(item, $event.target.checked);
          break;
        case mapHost.OpenStreet:
          this.toggleOnOpenStreet(item, $event.target.checked);
          break;
      }
    }
  }

  toggleOnGoogle(item, state: boolean) {
    item.drawedObjects?.forEach(
      obj => obj.setMap(state ? this.map.googleMap : null)
    );
  }

  toggleOnYandex(item, state: boolean) {
    item.drawedObjects?.forEach(
      obj => obj.options.set('visible', state)
    );
  }

  toggleOnOpenStreet(item, state: boolean) {
    item.drawedObjects?.forEach(
      obj => obj.setVisible(state)
    );
  }

  drawOverlay(bounds, image, svg, color?, item = null): USGSOverlay {
    const overlay = new USGSOverlay(bounds, image, svg, color, item);
    overlay.setMap(this.map.googleMap);
    this.changeDetectorRef.detectChanges();
    return overlay;
  }

  zoomChange(): void {
    // min zoom 0 ||  max zoom  22
    const zoom = this.map.googleMap.getZoom();
    const bordewidth = (17 - zoom) * 3;
    this.zoomEvent.next(bordewidth);
  }

  showonMap(): void {
    //  46:29:102219:5
    //  16:08:000000:177
    //  64:34:50201:38
    this.moveToElement();
  }

  moveToElement(element = null): void {
    if (this.cadItems && element == null) {
      element = this.findLastCheckedElement();
    }
    if (element != null) {
      const elementMins = this.meters2degress(
        element.cadItem.xmin,
        element.cadItem.ymin
      );
      const elementmaxs = this.meters2degress(
        element.cadItem.xmax,
        element.cadItem.ymax
      );

      switch (this.currentMap) {
        case mapHost.Google:
          this.moveToElementGoogle(elementMins, elementmaxs);
          break;
        case mapHost.Yandex:
          this.moveToElementYandex(element);
          break;
        case mapHost.OpenStreet:
          this.moveToElementOpenStreet(elementMins, elementmaxs);
          break;
      }

    }

  }

  moveToElementGoogle(mins: any[], maxs: any[]): void {
    if (!this.map?.googleMap) {
      return;
    }

    const elementBound = new google.maps.LatLngBounds(
      new google.maps.LatLng(mins[1], mins[0]),
      new google.maps.LatLng(maxs[1], maxs[0])
    );

    setTimeout(() => this.map.fitBounds(elementBound));
  }

  moveToElementYandex(element: ICadItems): void {
    if (!this.yandexMap) {
      return;
    }
    const bound = element.drawedObjects[0]?.geometry.getBounds();

    if (bound) {
      this.yandexMap.setBounds(bound, {duration: 500});
    }
  }

  moveToElementOpenStreet(mins: any[], maxs: any[]): void {
    if (!this.openStreetMap) {
      return;
    }

    const minCoords = [mins[0], mins[1]];
    const maxCoords = [maxs[0], maxs[1]];

    let extent = ol.extent.boundingExtent([minCoords, maxCoords]);
    extent = ol.proj.transformExtent(extent, ol.proj.get('EPSG:4326'), ol.proj.get('EPSG:3857'));

    this.openStreetMap.getView().fit(extent, {
      size: this.openStreetMap.getSize(),
      duration: 500
    });
  }

  findLastCheckedElement(): ICadItems {
    for (let i = this.cadItems.length - 1; i >= 0; i--) {
      const element = this.cadItems[i];

      const childElement = element.childItems
        .slice()
        .reverse()
        .find((x) => x.checked);

      if (childElement != null) {
        return childElement;
      }

      if (element.checked) {
        return element;
      }
    }
  }

  meters2degress(x, y): Array<any> {
    const lon = (x * 180) / 20037508.34;
    const lat =
      (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
    return [this.round(lon, 7), this.round(lat, 7)];
  }

  degrees2meters(lon, lat): Array<any> {
    const x = (lon * 20037508.34) / 180;
    let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180;
    return [x, y];
  }

  round(value, precision): any {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }


  //#region Red Lines

  loadTilesAdditionData() {
    this.layers.forEach(l => {
      l.overlays.map((x) => x.onRemove());
      l.overlays = [];
    });

    let zoomLevel = this.map.getZoom();
    const tiles = this.tileHelper.getAllTiles(this.map, zoomLevel);


    if (this.layers[LayerTypes.Border].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Border);
    }

    if (this.layers[LayerTypes.Zone].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Zone);
    }

    if (this.layers[LayerTypes.Territory].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Territory);
    }

    if (this.layers[LayerTypes.SpecialZone].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.SpecialZone);
    }

    if (this.layers[LayerTypes.Building].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Building);
    }

    if (this.layers[LayerTypes.Union].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Union);
    }


    if (this.layers[LayerTypes.Land].isEnabled) {
      if (zoomLevel > 12) {
        if (zoomLevel > 16) {
          zoomLevel = 16;
        }

        if (zoomLevel < 16) {
          this.loadLayersWith256(tiles, LayerTypes.Land);
        } else {
          this.loadLayersWith1024(tiles, zoomLevel, LayerTypes.Land);
        }
      }
    }
  }

  removePkkLayers(type) {
    this.layers[type].overlays.map((x) => x.onRemove());
    this.layers[type].overlays = [];
  }

  loadLayersWith256(tiles, type: LayerTypes) {
    tiles.map((tile) => {
      const latLng = this.tileHelper.getTileBounds(tile);

      const ne = new google.maps.LatLng(latLng.ne.lat, latLng.ne.lng);

      const sw = new google.maps.LatLng(latLng.sw.lat, latLng.sw.lng);
      const newbounds = new google.maps.LatLngBounds(sw, ne);

      this.loadLayers(newbounds, type);
    });
  }

  loadLayersWith1024(tiles, zoomLevel, type: LayerTypes) {
    let xs = [];
    let ys = [];

    tiles.map((tile) => {
      xs.push(tile.x);
      ys.push(tile.y);
    });

    xs = xs.filter((v, i, a) => a.indexOf(v) === i);
    ys = ys.filter((v, i, a) => a.indexOf(v) === i);

    if (xs.length % 2 !== 0) {
      xs.push(xs[0] + 1);
    }
    if (ys.length % 2 !== 0) {
      ys.push(ys[0] - 1);
    }

    xs.sort((a, b) => a - b);
    ys.sort((a, b) => a - b);

    for (let i = 0; i < xs.length; i += 2) {
      for (let j = 0; j < ys.length; j += 2) {
        const first = this.tileHelper.getTileBounds({
          x: xs[i + 1],
          y: ys[j],
          z: zoomLevel,
        });
        const second = this.tileHelper.getTileBounds({
          x: xs[i],
          y: ys[j + 1],
          z: zoomLevel,
        });

        const ne = new google.maps.LatLng(first.ne.lat, first.ne.lng);

        const sw = new google.maps.LatLng(second.sw.lat, second.sw.lng);
        const newbounds = new google.maps.LatLngBounds(sw, ne);
        this.loadLayers(newbounds, type);
      }
    }
  }

  loadLayers(bounds, type: LayerTypes): void {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const maxs = this.degrees2meters(ne.lng(), ne.lat());
    const mins = this.degrees2meters(sw.lng(), sw.lat());

    let imageUrl = null;
    switch (type) {
      case LayerTypes.Land:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A30%2C27%2C24%2C23%2C22&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Border:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/BordersGKN/MapServer/export?layers=show%3A0%2C5%2C16%2C25%2C32&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Zone:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?layers=show%3A6%2C11&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Territory:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?layers=show%3A5&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.SpecialZone:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?layers=show%3A4%2C3%2C2%2C1&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Building:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A30&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Union:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A35&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;

    }
    const overlay = this.drawOverlay(bounds, imageUrl, null);
    this.layers[type].overlays.push(overlay);
  }

  //#endregion

  maptypeDropdownHandler() {
    this.mapTypeDropdownIsOpened = !this.mapTypeDropdownIsOpened;
  }


  pkkLayersToggle(value, type) {
    this.layers[type].isEnabled = value;
    if (value) {
      this.loadTilesAdditionData();
    } else {
      this.removePkkLayers(type);
    }
  }

  //#region  for center icons

  addCenterMarkers(value) {
    this.centerMarkersEnabled = value;
    if (value) {
      this.cadItems.map(item => {
        if (item.childItems && item.childItems.length > 0) {
          item.childItems.forEach(child => {

            const coords = this.meters2degress(child.cadItem.xcenter, child.cadItem.ycenter);
            const contentString = `<div><p class="cad-number"> Номер  ${item.cadItem.key} </p><p class="show-on-text"> показать на </p><a target="_bank" class="google-nav-link" href="https://www.google.com/maps/search/?api=1&query=${coords[1]},${coords[0]}"><img class="external-map-icon" src="../../../assets/images/google-maps.svg"></a><a class="yandex-map-link" target="_blank" href="https://yandex.ru/maps/?pt=${coords[0]},${coords[1]}&z=18&l=map"><img class="external-map-icon" src="../../../assets/images/yandex_logo.svg"></a></div>`;

            this.cadCenterPositions.push({
              key: child.cadItem.key,
              isSelected: child.checked,
              position: {
                lat: coords[1],
                lng: coords[0],
              },
              // not used
              // label: {
              //   color: 'white',
              //   text: item.cadItem.key,
              // },
              info: {
                content: contentString
              },
              // title: item.cadItem.key,
              options: {animation: google.maps.Animation.DROP, icon: '/assets/images/target-circles.svg'},
            });

          });
        } else {
          const coords = this.meters2degress(item.cadItem.xcenter, item.cadItem.ycenter);
          const contentString = `<div><p class="cad-number"> Номер  ${item.cadItem.key} </p><p class="show-on-text"> показать на </p><a target="_bank" class="google-nav-link" href="https://www.google.com/maps/search/?api=1&query=${coords[1]},${coords[0]}"><img class="external-map-icon" src="../../../assets/images/google-maps.svg"></a><a class="yandex-map-link" target="_blank" href="https://yandex.ru/maps/?pt=${coords[0]},${coords[1]}&z=18&l=map"><img class="external-map-icon" src="../../../assets/images/yandex_logo.svg"></a></div>`;
          this.cadCenterPositions.push({
            key: item.cadItem.key,
            isSelected: item.checked,
            position: {
              lat: coords[1],
              lng: coords[0],
            },
            // not used
            // label: {
            //   color: 'white',
            //   text: item.cadItem.key,
            // },
            info: {
              content: contentString
            },
            // title: item.cadItem.key,
            options: {animation: google.maps.Animation.DROP, icon: '/assets/images/target-circles.svg'},
          });
        }
      });
    } else {
      this.removeCenterMarkers();
    }
  }

  removeCenterMarkers() {
    this.cadCenterPositions = [];
  }

  openInfo(marker: MapMarker, content) {
    this.cadInfoOnMap = content;
    this.info.open(marker);
  }

  //#endregion


  public handleOnClickGenInformation(child, showOverlay: boolean): void {
    child.open = true;
    if (showOverlay) {
      child.overlay.fill = true;
      child.overlay.onFill();
    }
  }

  public handleOverlayHide(child, withFill): void {
    child.open = false;
    if (this.isDrawnSVG) {
      child.overlay.fill = false;
      if (withFill) {
        child.overlay.onFill();
      }
    }
  }

  public handleItemCheckboxClicked(item, $event): void {
    item.checked = !item.checked;

    item.overlay?.toggle();
    if (item.drawedObjects?.length) {
      switch (this.currentMap) {
        case mapHost.Google:
          this.toggleOnGoogle(item, item.checked);
          break;
        case mapHost.Yandex:
          this.toggleOnYandex(item, item.checked);
          break;
        case mapHost.OpenStreet:
          this.toggleOnOpenStreet(item, item.checked);
          break;
      }
    }

    this.toggleChilds(item, $event);
  }

}

// http://localhost:4200/token-link/5b654e1c493148b33863448733812c93

interface LatLng {
  lat: number;
  lng: number;
}

export enum mapHost {
  Google = 'google',
  Yandex = 'yandex',
  OpenStreet = 'openstreetmap'
}

export enum LayerTypes {
  Land = 0,
  Border = 1,
  Zone = 2,
  Territory = 3,
  SpecialZone = 4,
  Building = 5,
  Union = 6
}
