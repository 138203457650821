export const colors = [
  { id: 1, color: 'red', value: '#CE2727', rgb: 'rgb(206, 39, 39)' },
  { id: 2, color: 'orange', value: '#FC9B23', rgb: 'rgb(252, 155, 35)' },
  { id: 3, color: 'yellow', value: '#F2C94C', rgb: 'rgb(242, 201, 76)' },
  { id: 4, color: 'neon', value: '#21DE56', rgb: 'rgb(33, 222, 86)' },
  { id: 5, color: 'green', value: '#5EBF50', rgb: 'rgb(94, 191, 80)' },
  { id: 6, color: 'babyblue', value: '#5FCCFB', rgb: 'rgb(95, 204, 251)' },
  { id: 7, color: 'blue', value: '#2373C2', rgb: 'rgb(35, 115, 194)' },
  { id: 8, color: 'indigo', value: '#203A6D', rgb: 'rgb(32, 58, 109)' },
  { id: 9, color: 'pink', value: '#FB7DCF', rgb: 'rgb(251, 125, 207)' },
  { id: 10, color: 'purple', value: '#B061DC', rgb: 'rgb(176, 97, 220)' },
];
