<div class="container">
  <div class="login-block">
    <h2 class="block-title">Вход</h2>
    <form (ngSubmit)="onSubmit(loginForm)" #loginForm="ngForm">
      <ul>
        <li>
          <input type="text" placeholder="Логин" id="clientName" name="clientName" [(ngModel)]="form.clientName"
            required>
        </li>
        <li>
          <input [type]="showPass ?   'text' :'password'" placeholder="Пароль" id="password" name="password"
            [(ngModel)]="form.password" required>
          <span *ngIf="!showPass" (click)="showPass = !showPass" class="eye-button show">
            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7.34615C1 7.34615 5.03846 1 11 1C16.9615 1 21 7.34615 21 7.34615" stroke="#909090"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1 7.34615C1 7.34615 5.03846 13.6923 11 13.6923C16.9615 13.6923 21 7.34615 21 7.34615"
                stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1 7.34615C1 7.34615 5.03846 4.26923 11 4.26923C16.9615 4.26923 21 7.34615 21 7.34615"
                stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M8.40269 4.50944C7.63515 5.21259 7.15387 6.22317 7.15387 7.34615C7.15387 9.47032 8.87585 11.1923 11 11.1923C13.1242 11.1923 14.8462 9.47032 14.8462 7.34615C14.8462 6.22317 14.3649 5.21259 13.5974 4.50944"
                stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <circle cx="11" cy="7.34615" r="1.34615" stroke="#909090" />
            </svg>
          </span>
          <span *ngIf="showPass" (click)="showPass = !showPass" class="eye-button hide">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 11.3462C2 11.3462 6.03846 17.6923 12 17.6923C17.9615 17.6923 22 11.3462 22 11.3462"
                stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12 20.0076V21.7576" stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.4871 18.5L18.0856 20.1445" stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M22.1621 15.0737L23.0371 16.5892" stroke="#909090" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M6.51294 18.5L5.9144 20.1445" stroke="#909090" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.84204 15.0737L0.967041 16.5892" stroke="#909090" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
        </li>
      </ul>
      <p *ngIf="WronLoginPass" class="error-text">Введены неверные логин и/или пароль</p>
      <button type="submit" class="blue-button">Продолжить</button>
    </form>
  </div>
</div>
