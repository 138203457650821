import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class SelectedPlotsService {
  public data: any[] = [];
  public plotSubject = new BehaviorSubject<any>([]);

  constructor(private dbService: NgxIndexedDBService) {
    this.dbService.getByKey('cadItems', 1).subscribe((groups)=>{
      if(!groups){
        this.plotSubject.next([]);
        this.dbService.update('cadItems', {id: 1, plotGroup: []}).subscribe();
      }else{
        this.plotSubject.next(groups['plotGroup']);
      }
    })
  }

  public async setPlotGroup(pageKey: string | number, plot){
    let cadItems: any = await this.dbService.getByKey('cadItems', 1).toPromise() || {id: 1, plotGroup: []};
    
    let groupIndex = cadItems.plotGroup.findIndex(val => val.key === pageKey);
    if(groupIndex != -1){
      let group = cadItems.plotGroup[groupIndex];

      let plotIndex = group.value.findIndex(val => val.id === plot.id);
      plotIndex == -1 ? group.value.push(plot) : group.value.splice(plotIndex, 1);

      group.value.length ? cadItems.plotGroup[groupIndex] = group : cadItems.plotGroup.splice(groupIndex, 1);

    }else{
      let plotGroups = {
        key: pageKey,
        value: [plot]
      };
      cadItems.plotGroup.push(plotGroups);
    }

    this.plotSubject.next(cadItems.plotGroup);
    this.dbService.update('cadItems', cadItems).subscribe();
  }

  public async setAllPlotGroups(pageKey: string | number, plots: Array<any>, isSelection: boolean){
    let cadItems: any = await this.dbService.getByKey('cadItems', 1).toPromise() || {id: 1, plotGroup: []};

    let groupIndex = cadItems.plotGroup.findIndex(val => val.key === pageKey);
    if(groupIndex != -1){
      let group = cadItems.plotGroup[groupIndex];
      
      plots.forEach(plot=>{
        let plotIndex = group.value.findIndex(val => val.id === plot.id);

        if(isSelection && plotIndex == -1){
          group.value.push(plot);
        }
        if(!isSelection && plotIndex != -1){
          group.value.splice(plotIndex, 1);
        }
      })
      group.value.length ? cadItems.plotGroup[groupIndex] = group : cadItems.plotGroup.splice(groupIndex, 1);
      
    }else{
      let plotGroups = {
        key: pageKey,
        value: plots
      };
      cadItems.plotGroup.push(plotGroups);
    }
    this.plotSubject.next(cadItems.plotGroup);
    this.dbService.update('cadItems', cadItems).subscribe();
  }

  public clearPlotGroup(){
    this.plotSubject.next([]);
    this.dbService.update('cadItems', {id: 1, plotGroup: []}).subscribe(()=>location.reload());
  }

  public async removeFromPlotGroup(plot){
    let cadItems: any = await this.dbService.getByKey('cadItems', 1).toPromise() || {id: 1, plotGroup: []};
    
    cadItems.plotGroup.forEach((e, ind, obj) => {
      let index = e.value.findIndex(val => val.id === plot.id);
      index === -1 ? '' : e.value.splice(index, 1);
      e.value.length ? '' : obj.splice(ind, 1);
    });
    
    this.plotSubject.next(cadItems.plotGroup);
    this.dbService.update('cadItems', cadItems).subscribe();
  }
}
