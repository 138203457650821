import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment'
import { AuthService } from './auth.service';

const BACKEND_URL = environment.appUrl;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if(req.url.startsWith(BACKEND_URL)) {

      const authToken = this.authService.getToken() || '';
      const authRequest = req.clone({
        headers: req.headers.set('Authorization', authToken),
      });
      return next.handle(authRequest);
    }
    else {
      return next.handle(req);
    }
  }
}
