import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map, retryWhen} from 'rxjs/operators';
import {DownloadService} from './download.service';
import * as imageHelper from '../../../imagetracer_v1.2.6';
import {genericRetryStrategy} from '../shared/utils/generic-retry-strategy';
import {of} from 'rxjs';

const BACKEND_URL = environment.appUrl;

@Injectable({providedIn: 'root'})
export class CadastralService {
  private backUrls = {
    getFromDbchild: 'pkk/get-from-db-childs',
    getFromDb: 'pkk/get-from-db',
    add: 'pkk/add',
    addImageBase64: 'pkk/add-image-base64',
    updateParentKeys: 'pkk/update-parent-keys',
    updateCoordinates: 'pkk/update-cordinats'
  };
  countOfPkks = 0;
  allPkkCount = 0;

  constructor(
    private httpClient: HttpClient,
    private downloads: DownloadService
  ) {
  }

  async getPkksByLatLon(lat, lng): Promise<any> {
    const url = 'https://pkk.rosreestr.ru/api/features/1?text=' + lat + '+' + lng + '&limit=40&skip=0&inPoint=true&tolerance=4';
    const data = await this.loadDataFromPkk(url);
    return data;
  }

  async getPkksByRegion(regionKey: string): Promise<any> {
    const url = 'https://pkk.rosreestr.ru/api/features/1?text=' + regionKey + '/*';
    const data = await this.loadDataFromPkk(url);
    let skip = 0;
    /*if (!data.features) {
      // todo show error message
      alert('Мы получили неверные данные. Пожалуйста, попробуйте позднее.');
      return;
    }*/
    while (data.total > data.features.length) {
      skip += 40;
      const nextData = await this.loadDataFromPkk(url + '&limit=40&skip=' + skip);
      if (nextData.features.length === 0) {
        break;
      }
      data.features.push(...nextData.features);
    }
    const keys = data.features.map((x) => {
      return x.attrs.id;
    });

    const CadNumbers = keys.map((x) => {
      return {keys: x};
    });
    return await this.getPkkchilds(CadNumbers, regionKey);
  }

  async getPkkchilds(CadNumbers: Array<any>, regionKey: string): Promise<any> {
    const cadNumbers = Array<string>();
    for (const key in CadNumbers) {
      if (Object.prototype.hasOwnProperty.call(CadNumbers, key)) {
        cadNumbers.push(CadNumbers[key].keys);
      }
    }

    const data = await this.getFromDbchilds(CadNumbers);
    const havingKeys = data.data.map((x) => {
      return x.key;
    });
    if (data.data.length !== CadNumbers.length) {
      const notHavingKeys = cadNumbers.filter((x) => {
        if (havingKeys.indexOf(x) === -1) {
          return x;
        }
      });
      if (notHavingKeys.length > 0) {
        const externaldata = await this.getFromPkk(notHavingKeys);
        const addedData = await this.addPkk(externaldata);
        data.data.push(...addedData.data);
      }
    }
    await this.checkOrupdateParentKeys(data.data, regionKey);
    await this.generateAndUploadImages(data.data);

    return data;
  }

  async checkOrupdateParentKeys(pkks: IPkkDto[], regionKey): Promise<any> {
    const needToUpdatePkks = pkks.filter(x => !x.parentKey);

    if (needToUpdatePkks.length > 0) {
      const data = {
        parentKey: regionKey,
        keys: []
      };

      needToUpdatePkks.map(x => data.keys.push(x.key));

      await this.updateParentKeys(data);
    }
  }


  async getPkk(CadNumbers: Array<any>): Promise<any> {
    this.allPkkCount = CadNumbers.length;
    this.countOfPkks = 0;
    const cadNumbers = Array<string>();
    for (const key in CadNumbers) {
      if (Object.prototype.hasOwnProperty.call(CadNumbers, key)) {
        cadNumbers.push(CadNumbers[key].keys);
      }
    }
    const data = await this.getFromDb(CadNumbers);
    const havingKeys = data.data.map((x) => {
      return x.key;
    });
    // this.countOfPkks += havingKeys.length;
    if (data.data.length !== CadNumbers.length) {
      const notHavingKeys = cadNumbers.filter((x) => {
        if (havingKeys.indexOf(x) === -1) {
          return x;
        }
      });
      if (notHavingKeys.length > 0) {
        const externaldata = await this.getFromPkk(notHavingKeys);
        const addedData = await this.addPkk(externaldata);
        data.data.push(...addedData.data);

      }
    }
    await this.generateAndUploadImages(data.data);
    return data;
  }

  async updateNoImagesPkks(data: IPkkDto): Promise<boolean> {
    const xmax = +data.xmax;
    const xmin = +data.xmin;
    const ymax = +data.ymax;
    const ymin = +data.ymin;
    const coeficient = (xmax - xmin) / (ymax - ymin);
    const xcenter = (xmax + xmin) / 2;
    const ycenter = (ymax + ymin) / 2;

    let imgHeight = 4096;
    let imgWidth = 4096;
    if (coeficient > 1) {
      imgHeight = imgHeight / coeficient;
    }
    if (coeficient < 1) {
      imgWidth = imgWidth * coeficient;
    }

    let url1 = '';
    let url2 = '';
    let url3 = '';
    let url4 = '';

    if (data.key.indexOf('/') !== -1) {
      url1 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xmin}%2C${ymin}%2C${xcenter}%2C${ycenter}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A8%2C9&layerDefs=%7B%228%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%2C%229%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%7D&f=image`;
      url2 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xmin}%2C${ycenter}%2C${xcenter}%2C${ymax}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A8%2C9&layerDefs=%7B%228%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%2C%229%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%7D&f=image`;
      url3 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xcenter}%2C${ycenter}%2C${xmax}%2C${ymax}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A8%2C9&layerDefs=%7B%228%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%2C%229%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%7D&f=image`;
      url4 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xcenter}%2C${ymin}%2C${xmax}%2C${ycenter}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A8%2C9&layerDefs=%7B%228%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%2C%229%22%3A%22id%20=%20%27${data.key.split('/')[0].split(':').join('%3A')}%27%20and%20contour_num%20=%20${data.key.split('/')[1]}%22%7D&f=image`;
    } else {
      url1 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xmin}%2C${ymin}%2C${xcenter}%2C${ycenter}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A6%2C7%2C8%2C9&layerDefs=%7B%226%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%227%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%228%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%229%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%7D&f=image`;
      url2 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xmin}%2C${ycenter}%2C${xcenter}%2C${ymax}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A6%2C7%2C8%2C9&layerDefs=%7B%226%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%227%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%228%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%229%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%7D&f=image`;
      url3 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xcenter}%2C${ycenter}%2C${xmax}%2C${ymax}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A6%2C7%2C8%2C9&layerDefs=%7B%226%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%227%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%228%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%229%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%7D&f=image`;
      url4 = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?bbox=${xcenter}%2C${ymin}%2C${xmax}%2C${ycenter}&bboxSR=102100&imageSR=102100&size=${imgWidth}%2C${imgHeight}&dpi=96&format=png&transparent=true&layers=show%3A6%2C7%2C8%2C9&layerDefs=%7B%226%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%227%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%228%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%2C%229%22%3A%22ID%20=%20%27${data.key.split(':').join('%3A')}%27%22%7D&f=image`;
    }


    const image1 = await imageHelper.addImageProcess(url1, true);
    const image2 = await imageHelper.addImageProcess(url2, true);
    const image3 = await imageHelper.addImageProcess(url3, true);
    const image4 = await imageHelper.addImageProcess(url4, true);

    const canvas = document.createElement('canvas');
    canvas.width = image1.width + image2.width;
    canvas.height = image1.height + image2.height;
    const context = canvas.getContext('2d');
    context.drawImage(image2, 0, 0);
    context.drawImage(image3, image1.width, 0);
    context.drawImage(image1, 0, image1.height);
    context.drawImage(image4, image1.width, image1.height);

    const blob = await imageHelper.getCanvasBlob(canvas);

    return await this.generateSvgAngUploadImage(blob, data, true);
  }


  async generateAndUploadImages(data: IPkkDto[]) {
    for (let index = 0; index < data.length; index++) {
      if (
        data[index].typeParcel !== 'Единое землепользование' &&
        data[index].typeParcel !== 'Контуры МКЗУ' &&
        !data[index].hasNoImage
        // TODO: Hayk avelacnel stugumy nkar petq a qashel te che
      ) {
        if (!data[index].parentKey && !data[index].key.includes('/')) {
          this.countOfPkks++;
        }
        if (data[index].imageBase64 == null &&
          data[index].imageSvg == null) {
          let retryCount = 0;
          let blob = null;
          while (retryCount < 3 && blob == null) {
            try {
              blob = await this.downloads.download(
                data[index].imageUrl.replace('png32', 'png')
              );
            } catch {
              retryCount++;
            }
          }

          if (blob == null) {
            data[index].downloadException = true;
            continue;
          }
          data[index].downloadException = false;

          if (data[index].currentPageSizeIndex == null) {
            data[index].currentPageSizeIndex = 0;
          }
          if (this.checkForEmpty(data[index].currentPageSizeIndex, blob.size)) {
            const nextSize = this.getNextSize(data[index].currentPageSizeIndex);

            if (nextSize == null) {
              continue;
            }

            data[index].currentPageSizeIndex++;
            const indexOfSize = data[index].imageUrl.indexOf('&size=');
            const removeIndex = data[index].imageUrl.indexOf('&', indexOfSize + 1);
            const size = data[index].imageUrl.slice(indexOfSize + 1, removeIndex);
            data[index].imageUrl = data[index].imageUrl.replace(size, nextSize);

            index--;
            continue;
          }

          await this.generateSvgAngUploadImage(blob, data[index]);
        } else if (data[index].imageBase64 != null && data[index].imageSvg == null) {
          await this.generateSvgAngUploadImage(this.base64ToBlob(data[index].imageBase64), data[index]);
        }
      }
      if (index + 1 === data.length && (data[index].parentKey || data[index].key.includes('/'))) {
        this.countOfPkks++;
      }
    }
  }

  async generateSvgAngUploadImage(blob, data: IPkkDto, isSvgMandatory = false): Promise<boolean> {
    const objectUrl = URL.createObjectURL(blob);
    let x;
    try {
      const tm = setTimeout(() => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = (d) => {
            console.log('setTimeout:  ', d);
            resolve(img);
          };
          img.onerror = () => reject(new Error('could not load image'));
          img.src = objectUrl;
        });
      }, 3000);
      x = await imageHelper.imageToSVGAsync(objectUrl);
      clearTimeout(tm);
    } catch (e) {
      debugger;
    }
    const svgElement = document.createElement('div');
    svgElement.innerHTML = x;

    const mustRemove = [];
    svgElement.firstChild.childNodes.forEach((child) => {
      if (child.nodeType === 1) {
        const current = child as HTMLElement;
        if (current.attributes.getNamedItem('opacity').nodeValue == '0') {
          mustRemove.push(child);
        }
      }
    });
    mustRemove.forEach((element) => {
      svgElement.firstChild.removeChild(element);
    });

    const base64data = (await this.blobToData(blob))
      .toString()
      .replace(/^data:image\/(png|jpg);base64,/, '');

    const updateImageModel = {
      key: data.key,
      imageUrl: data.imageUrl,
      imageBase64: base64data,
      imageSvg: null,
    };

    data.imageBase64 = base64data;
    if (svgElement.firstChild.childNodes.length !== 0) {
      updateImageModel.imageSvg = svgElement.innerHTML;
      data.imageSvg = svgElement.innerHTML;
    } else if (isSvgMandatory) {
      updateImageModel.imageBase64 = null,
        await this.updateImageBase64(updateImageModel);
      return false;
    }

    const res = await this.updateImageBase64(updateImageModel);

    return !res.hasError;
  }

  public getNextSize(number) {
    switch (number) {
      case 0:
        return 'size=1920%2C1080';
      case 1:
        return 'size=4096%2C4096';
      default:
        return null;
    }
  }

  public checkForEmpty(number, size) {
    switch (number) {
      case 0:
        if (size == 1243) {
          return true;
        }
        break;
      case 1:
        if (size == 2152) {
          return true;
        }
        break;
      case 2:
        if (size == 16452) {
          return true;
        }
        break;
      default:
        return false;
    }

    return false;
  }

  public blobToData = (blob: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  public base64ToBlob(data: string): Blob {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: 'image/png'});
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return theBlob as File;
  }

  async updateImageBase64(data: any): Promise<IResponse<IPkkDto>> {
    return await this.httpClient
      .post<IResponse<IPkkDto>>(
        BACKEND_URL + this.backUrls.addImageBase64,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      )
      .toPromise();
  }

  async updateCoordinates(data: any): Promise<IResponse<any>> {
    return await this.httpClient
      .post<IResponse<IPkkDto>>(
        BACKEND_URL + this.backUrls.updateCoordinates,
        data
      ).toPromise();
  }

  async updateParentKeys(data: any): Promise<IResponse<boolean>> {
    return await this.httpClient
      .post<IResponse<boolean>>(
        BACKEND_URL + this.backUrls.updateParentKeys,
        data
      )
      .pipe(
        map((response) => {
          return response;
        })
      )
      .toPromise();
  }

  async addPkk(data: any): Promise<IResponse<Array<IPkkDto>>> {
    return await this.httpClient
      .post<IResponse<Array<IPkkDto>>>(BACKEND_URL + this.backUrls.add, data)
      .pipe(
        map((response) => {
          return response;
        })
      )
      .toPromise();
  }

  async getFromPkk(keys: Array<string>): Promise<Array<any>> {
    const pkks = [];

    for (let index = 0; index < keys.length; index++) {
      // this.countOfPkks++;

      const url = 'https://pkk.rosreestr.ru/api/features/1/' + keys[index]; // TODO: move to constants

      let pkk = await this.loadDataFromPkk(url);

      if (pkk.feature == null) {
        pkk = await this.checkIsRegion(keys[index]);

        if (pkk == null) {
          continue;
        }
      }

      pkks.push({key: keys[index], data: JSON.stringify(pkk)});

    }

    return pkks;
  }

  async checkIsRegion(key: string): Promise<any> {
    const url = 'https://pkk.rosreestr.ru/api/features/1?text=' + key;

    let pkk = await this.loadDataFromPkk(url);

    if (
      pkk.features != null &&
      pkk.features[0]?.attrs?.id != null &&
      pkk.features.length === 1
    ) {
      pkk = await this.loadDataFromPkk(
        'https://pkk.rosreestr.ru/api/features/1/' + pkk.features[0]?.attrs?.id
      );
      return pkk;
    }

    return null;
  }

  async loadDataFromPkk(url: string): Promise<any> {
    return await this.httpClient
      .get<any>(url)
      .pipe(
        retryWhen(genericRetryStrategy()),
        map((response) => response),
        catchError(error => of(error))
      )
      .toPromise();
  }

  async getFromDbchilds(
    CadNumbers: Array<any>
  ): Promise<IResponse<Array<IPkkDto>>> {
    return await this.httpClient
      .post<IResponse<Array<IPkkDto>>>(
        BACKEND_URL + this.backUrls.getFromDbchild, CadNumbers.map(x => x.keys)
      )
      .pipe(
        map((response) => {
          if (response && response.data && response.data.length > 0 && typeof String.prototype.localeCompare === 'function') {
            response.data.sort((a, b) => a.key.localeCompare(b.key, undefined, { numeric: true }));
          }
          return response;
        })
      )
      .toPromise();
  }


  async getFromDb(CadNumbers: Array<any>): Promise<IResponse<Array<IPkkDto>>> {

    return await this.httpClient
      .post<IResponse<Array<IPkkDto>>>(
        BACKEND_URL + this.backUrls.getFromDb, CadNumbers.map(c => c.keys))
      .pipe(
        map((response) => {
          return response;
        })
      )
      .toPromise();
  }
}

export interface IResponse<T> {
  data: T;
  hasError: boolean;
  errors: [];
}

export interface IPkkDto {
  key: string;
  parentKey: string;
  type: string;
  typeParent: string;
  typeParcel: string;
  cadastralNumber: string;
  cadastralQuarter: string;
  statusId: string;
  cadastralCost: number;
  cadUnitId: string;
  areaValue: number;
  areaUnitId: string;
  address: string;
  categoryTypeId: string;
  utilCodeId: string;
  byDocument: string;
  hasNoImage: boolean;
  imageUrl: string;
  imageBase64: string;
  imageSvg: string;
  cordinats: string;
  xmin: string;
  ymin: string;
  xmax: string;
  ymax: string;
  xcenter: string;
  ycenter: string;
  jsonData: string;
  categoryType: IAreaUnit;
  status: IAreaUnit;
  utilCode: IAreaUnit;
  areaUnit: IAreaUnit;
  cadUnit: IAreaUnit;
  id: number;
  createdDate: Date;
  modifyDate: Date;
  currentPageSizeIndex: number;
  downloadException: boolean;
}

export interface IAreaUnit {
  id: string;
  name: string;
}
