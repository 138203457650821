import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '../../auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse && event.body.hasError) {
            event.body.errors.map((err) => {
              if (err.code === 2 || err.code === 5) {
                this.authService.logoutUserOffline();
              }
              return throwError(err);
            });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.authService.logoutUserOffline();
          }
          return throwError(error);
        }
      )
    );
  }
}
