<div class="popup">
  <div class="popup-inner">
    <button class="link w-30 close" (click)="closePopup()">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.05078 2.05025L11.9503 11.9498" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M2.05078 11.9498L11.9503 2.05026" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </button>
    <p class="block-title">Новая папка</p>
    <input type="text" (click)="folderName === 'Новая папка' ? folderName = '':null" [(ngModel)]="folderName">
    <button class="blue-button" [disabled]="folderName == ''" (click)="CreateFolder()">Создать</button>

  </div>
</div>
