import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {FormsModule} from '@angular/forms';
import {AuthGuard} from './auth/auth.guard';
import {AuthInterceptor} from './auth/auth.interceptor';
import {ErrorInterceptor} from './auth/error/error-interceptor';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
registerLocaleData(localeFr);

const dbConfig: DBConfig  = {
  name: 'pkkDataDb',
  version: 7,
  objectStoresMeta: [{
    store: 'cadItems',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'plotGroup', keypath: 'plotGroup', options: { unique: false } }
    ]
  }]
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NgxPaginationModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ToasterModule,
    NgSelectModule,
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [
    AuthGuard,
    ToasterService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
