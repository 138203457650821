import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeService } from '../../home.service';

@Component({
  selector: 'app-item-move-popup',
  templateUrl: './item-move-popup.component.html',
  styleUrls: ['./item-move-popup.component.scss'],
})
export class ItemMovePopupComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public newFolder = 'Новая папка';
  public newFolderMode = false;
  @Output() MoveEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  folders: Array<any>;

  constructor(private homeService: HomeService) {}
  public selectedFolderId: number;
  public folderName: string;


  ngOnInit(): void {
    this.folders = this.homeService.folders;
  }

  onMove(): void {

    if (this.newFolderMode) {
      this.homeService
        .addFolder({ name: this.newFolder })
        .then((res) => {
          this.selectedFolderId = res.data.id;
          this.folderName = res.data.name;
          this.MoveEvent.emit({
            id: this.selectedFolderId,
            name: this.folderName,
          });
        });
    } else {
      this.MoveEvent.emit({ id: this.selectedFolderId, name: this.folderName });
    }
  }

  close(): void {
    this.closeEvent.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
