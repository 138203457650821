/* tslint:disable:typedef */
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import {Router} from '@angular/router';
import html2canvas from 'html2canvas';
import {BehaviorSubject, Subject} from 'rxjs';
import {ICadItems} from 'src/app/shared/models/ICadItems';
import {HomeService} from '../home.service';
import * as saveAs from 'file-saver';
import {TileHelper} from '../../../../tile-helper';
import {debounceTime, distinctUntilChanged, min, tap} from 'rxjs/operators';
import {USGSOverlay} from './map-overlay';
import {IitemMove} from 'src/app/shared/models/IFolderModel';
import {CustomResModel} from 'src/app/shared/models/ICustom.model';
import {colors} from 'src/app/shared/colors.constants';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {DownloadService} from 'src/app/services/download.service';
import Protobuf from 'pbf';
import {VectorTile} from '@mapbox/vector-tile';
import {arcgisPbfDecode} from 'arcgis-pbf-parser';
import * as geobuf from 'geobuf';
import {CadastralService} from 'src/app/services/cadastral.service';
import {environment} from '../../../environments/environment';

declare var ymaps: any; // Yandex Maps
declare var ol: any; // Open Layers (OpenStreetMap)

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  infoWindow = new google.maps.InfoWindow({
    content: null,
    position: null,
  });
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('mymap', {static: false}) map: any;
  @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;

  public mapHost = mapHost;
  public currentMap: string = mapHost.Google;
  public prevMapCenter: any;
  public prevMapZoom: any;
  public yandexMap: any;
  public openStreetMap: any;
  public currentPosition: any;
  public cadCenterPositions: any = [];
  public colors = colors;
  public subject = new BehaviorSubject(null);
  public showDublicatePopup: boolean;
  public cadSearch = '';
  public zoomEvent = new Subject();
  public toaster = false;
  public copyToas = false;
  public showLoading = true;
  public center: any = {lat: 55.75490171455126, lng: 37.62086691244819};
  public mapTypeId = 'hybrid';
  public div: any;
  public cadItems: ICadItems[] = [];
  public selecdetPrice = 0;
  public scuare = 0;
  public selectedCadItems = [];
  public KeyList = '';
  public toFilderName = [];
  public divergencePopup = false as any;
  public link = '';
  public layerTypes = LayerTypes;
  public layers = [{
    type: LayerTypes.Land,
    isEnabled: false,
    overlays: []
  },
    {
      type: LayerTypes.Border,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Zone,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Territory,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.SpecialZone,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Building,
      isEnabled: false,
      overlays: []
    },
    {
      type: LayerTypes.Union,
      isEnabled: false,
      overlays: []
    }];
  public centerMarkersEnabled = false;
  public tagTypes = [{name: 'Все', id: 0}] as any;
  public geometryCalcs: { distance: number | string, area: number | string, hectare: number | string } = {} as any;
  public cadNumbers: any[] = [];
  public polyLines = [];
  tempdata;
  options: google.maps.MapOptions = {
    zoom: 13,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };
  public tileHelper: TileHelper;
  public itemMovePopup: boolean;
  public createFolderPopup: boolean;
  isSubscribedToBoundChange = false;
  public mapTypeDropdownIsOpened = false;
  cadInfoOnMap: any;
  public rightBarOpen: boolean;
  cordinLatLngs: any;
  mapTypeName = 'Гибрид';
  drawingManager: google.maps.drawing.DrawingManager;

  private isAllCadItemsLoaded = false;
  private isAllComputed = false;
  private isDrawnByCoords = false;
  private isDrawnSVG = false;
  private tempColor: any;

  constructor(
    public homeService: HomeService,
    private changeDetectorRef: ChangeDetectorRef,
    public router: Router,
    public downloads: DownloadService,
    public cad: CadastralService,
  ) {
    const currentPkks = sessionStorage.getItem('pkks');
    // if (this.homeService.pkkKeys && this.homeService.pkkKeys.length == 0) {
    this.homeService.pkkKeys = JSON.parse(currentPkks);
    // this.homeService.pkkKeys.sort((a, b) => +a.order - +b.order);
    // }
    this.tileHelper = new TileHelper();
  }

  @HostListener('document:addToSearch', ['$event'])
  addToSearch(e) {
    this.resetLoadedConditions();
    this.clearMap();
    this.cadNumbers = this.cadItems.map(c => {
      return {keys: c.cadItem.key};
    });
    if (this.cadNumbers.find(c => c.keys == (e as any).detail) == null) {
      this.cadNumbers.push({keys: (e as any).detail});
    }

    this.zoomEvent
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((e) => {
          if (!this.isDrawnSVG) {
            return;
          }
          // if (!this.isSubscribedToBoundChange) {
          //   // this.map.googleMap.addListener('dragend', () => this.loadRedLines());
          //   // this.map.googleMap.addListener('zoom_changed', () => this.loadRedLines());
          //   this.map.googleMap.addListener('tilesloaded', () =>
          //     this.loadTilesAdditionData()
          //   );
          //   this.isSubscribedToBoundChange = true;
          // }
          this.cadItems.forEach((el) => {
            if (el.cadItem.typeParcel !== 'Контуры МКЗУ') {
              el.overlay?.onChangeBorderWidth(e === 0 ? 3 : e);
            }
            if (el.childItems.length) {
              el.childItems.forEach((el) => {
                el.overlay?.onChangeBorderWidth(e === 0 ? 3 : e);
              });
            }
          });
        })
      )
      .subscribe();


    this.loadingPercent();
    if (this.isDrawnSVG) {
      this.cadItems.forEach((element) => {
        element.overlay?.toggleDOM(this.map);
        element.childItems?.forEach((child) => {
          child.overlay?.toggleDOM(this.map);
        });
      });
    }
    this.homeService.pkkKeys = this.cadNumbers;
    sessionStorage.setItem('pkks', JSON.stringify(this.cadNumbers));
    this.loadData();
  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    this.homeService.getCalcCordinatSubject.subscribe(data => {
      if (data) {
        this.cordinLatLngs = data;
      }
    });
    if (this.cordinLatLngs) {
      sessionStorage.removeItem('pkks');
      this.getPoligon();
    } else {
    }

    this.getTagTypes();
    this.subject.subscribe((res) => {
      this.homeService.getFolders();
      this.popUpConfirmed(res);
    });
    if (
      this.homeService.tokenGeneratedPkks &&
      this.homeService.tokenGeneratedPkks.length
    ) {
      this.homeService.tokenGeneratedPkks.map((element) => {
        element.pkk.colorAreaId = element.colorAreaId;
        element.pkk.pkkTagTypeId = element.pkkTagTypeId;
        element.pkk.shareInTheRight = element.shareInTheRight;
        const childItems = element.childPKKs.map(x => {
          return {
            cadItem: x,
            overlay: null,
            bound: null,
            checked: true,
          };
        });
        return this.cadItems.push({
          cadItem: element.pkk,
          overlay: null,
          bound: null,
          checked: true,
          childItems,
        });
      });

      this.fillDataFromMap();
      this.cadItems?.forEach(x => this.fillChildDataFromMap(x));
      this.sumAmionth();
      this.showLoading = false;
    } else {
      this.loadData();
    }

    this.addMainZoomEventOnGoogleMaps();

    this.changeDetectorRef.detectChanges();
  }

  public toggleBurger() {
    this.homeService.burgerOpen = !this.homeService.burgerOpen;

    if (this.currentMap == mapHost.OpenStreet) {
      setTimeout(() => {
        this.openStreetMap.updateSize();
      }, 500);
    }
  }

  public addMainZoomEventOnGoogleMaps() {
    this.zoomEvent
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((e) => {
          if (!this.isSubscribedToBoundChange) {
            // this.map.googleMap.addListener('dragend', () => this.loadRedLines());
            // this.map.googleMap.addListener('zoom_changed', () => this.loadRedLines());

            if (this.currentMap !== mapHost.Google) {
              return;
            }

            this.map.googleMap.addListener('click', async (mapsMouseEvent) => {
              const res = await this.homeService.getpkkByLatLong(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng());

              if (res.features.length > 0) {
                const cadasterNo = res.features[0].attrs.cn;
                this.infoWindow.close();
                this.infoWindow = new google.maps.InfoWindow({
                  position: mapsMouseEvent.latLng,
                });
                this.infoWindow.setContent(
                  `<p style="color: #222; font-size: 14px; margin-bottom: 8px;">${cadasterNo}</p>
                <p style="color: #222; font-size: 14px;">Земельный участок</p>
                <button style="color: #3163B2; font-size: 14px; margin: 10px; position: absolute; bottom: 3px; right: 3px;" onclick="addEvent('${cadasterNo}')">добавить в поиск</button>`
                );
                this.infoWindow.open(this.map.googleMap);
              }

            });

            this.map.googleMap.addListener('tilesloaded', () =>
              this.loadTilesAdditionData()
            );
            this.isSubscribedToBoundChange = true;
          }

          if (!this.isAllCadItemsLoaded || !this.isDrawnSVG) {
            return;
          }
          this.cadItems.forEach((el) => {
            if (el.cadItem.typeParcel !== 'Контуры МКЗУ') {
              el.overlay?.onChangeBorderWidth(e === 0 ? 3 : e);
            }
            if (el.childItems.length) {
              el.childItems.forEach((el) => {
                el.overlay?.onChangeBorderWidth(e === 0 ? 3 : e);
              });
            }
          });
        })
      )
      .subscribe();
  }

  public getPoligon() {
    this.options = {
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      zoom: 16,
      center: this.cordinLatLngs[0][0],
      mapTypeId: this.mapTypeId,
    };

    this.cordinLatLngs.forEach(c => {
      const bermudaTriangle = new google.maps.Polygon({
        paths: c,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0,
        editable: true
      });
      const distance = google.maps.geometry.spherical.computeLength(bermudaTriangle.getPath().getArray()) / 1000;
      const area = google.maps.geometry.spherical.computeArea(bermudaTriangle.getPath().getArray());
      this.geometryCalcs.distance = distance.toFixed(3);
      this.calculateArea(area);

      bermudaTriangle.addListener('mouseup', (e) => {
        const editedDistance = google.maps.geometry.spherical.computeLength(bermudaTriangle.getPath().getArray()) / 1000;
        const editedArea = google.maps.geometry.spherical.computeArea(bermudaTriangle.getPath().getArray());
        this.geometryCalcs.distance = editedDistance.toFixed(3);
        this.calculateArea(editedArea);
      });

      bermudaTriangle.setMap(this.map.googleMap);
    });

  }

  public removeDrawings(): void {
    this.polyLines.forEach(element => {
      element.setMap(null);
    });

    this.geometryCalcs = {
      distance: 0,
      area: 0,
      hectare: 0
    };

    this.drawingManager.setMap(null);
  }

  public addPolyline(event): void {
    if (event.target.checked) {
      this.loadMapDrawingTools();
    } else if (!event.target.checked) {
      this.removeDrawings();
    }
  }

  public addYandexPolyline(event) {
    if (event.target.checked) {

      // Creating a polygon with no vertices.
      const myPolygon = new ymaps.Polygon([], {}, {
        editorDrawingCursor: 'crosshair',
        editorMaxPoints: 5000,
        fillColor: 'rgba(0,255,0,0.2)',
        strokeColor: '#0000FF',
        strokeWidth: 2,
        editorMenuManager: (items) => {
          items.push({
            title: 'Расчет Площади',
            onClick: () => {
              ymaps.ready(['util.calculateArea']).then(() => {
                const area = ymaps.util.calculateArea(myPolygon);
                this.calculateArea(area);
                /*                  const areaFromJson = ymaps.util.calculateArea({
                                    type: 'Feature',
                                    geometry: {
                                      type: 'Polygon',
                                      coordinates: myPolygon.geometry.getCoordinates()
                                    }
                                  });
                                  console.log(areaFromJson);*/

              });
            }
          });
          return items;
        }
      });
      // Adding the polygon to the map.
      this.yandexMap.geoObjects.add(myPolygon);

      // In the mode for adding new vertices, we change the stroke color of the polygon.
      const stateMonitor = new ymaps.Monitor(myPolygon.editor.state);
      stateMonitor.add('drawing', (newValue) => {
        myPolygon.options.set('strokeColor', newValue ? '#FF0000' : '#0000FF');
      });

      // Turning on the edit mode with the possibility of adding new vertices.
      myPolygon.editor.startDrawing();
      // @ts-ignore
      window['myPolygon'] = myPolygon;

    } else if (!event.target.checked) {
      // @ts-ignore
      this.yandexMap.geoObjects.remove(window.myPolygon);
    }
  }

  calculateArea(area) {
    this.geometryCalcs.area = (area / 1000000).toFixed(5);
    this.geometryCalcs.hectare = (area / 10000).toFixed(5);
  }

  private loadMapDrawingTools(): void {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYLINE,
        ],
      },
      circleOptions: {
        fillColor: '#ffff00',
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
      polylineOptions: {
        clickable: true,
        editable: true,
      },
    });
    this.drawingManager.setMap(this.map.googleMap);
    this.drawingManager.addListener('polylinecomplete', (event) => {
      const distance = google.maps.geometry.spherical.computeLength(event.getPath().getArray()) / 1000;
      const area = google.maps.geometry.spherical.computeArea(event.getPath().getArray());
      this.geometryCalcs.distance = distance.toFixed(3);
      this.calculateArea(area);
      this.polyLines.push(event);
      event.addListener('mouseup', (e) => {
        const editedDistance = google.maps.geometry.spherical.computeLength(event.getPath().getArray()) / 1000;
        const editedArea = google.maps.geometry.spherical.computeArea(event.getPath().getArray()) ;
        this.geometryCalcs.distance = editedDistance.toFixed(3);
        this.calculateArea(editedArea);
      });
    });

  }

  generateAccessToken(): void {
    const data = {
      pkkOptions: [],
    };
    this.cadItems.forEach((el) => {
      if (el.checked) {
        data.pkkOptions.push({
          pkkId: el.cadItem.pkkId ? el.cadItem.pkkId : el.cadItem.id,
          pkkTagTypeId: el.cadItem.pkkTagTypeId
            ? el.cadItem.pkkTagTypeId
            : null,
          colorAreaId: el.cadItem.colorAreaId ? el.cadItem.colorAreaId : null,
          shareInTheRight: el.cadItem.shareInTheRight
            ? el.cadItem.shareInTheRight
            : null,
        });
      } else {
        if (el.childItems?.length) {
          el.childItems.forEach(child => {
            if (child.checked) {
              data.pkkOptions.push({
                pkkId: el.cadItem.pkkId ? el.cadItem.pkkId : child.cadItem.id,
                pkkTagTypeId: child.cadItem.pkkTagTypeId
                  ? child.cadItem.pkkTagTypeId
                  : null,
                colorAreaId: child.cadItem.colorAreaId ? child.cadItem.colorAreaId : null,
                shareInTheRight: child.cadItem.shareInTheRight
                  ? child.cadItem.shareInTheRight
                  : null,
              });
            }
          });
        }
      }
    });
    this.homeService
      .generateAccessToken(data)
      .subscribe((res: CustomResModel<any>) => {
        if (res.data) {
          this.link = window.location.origin + '/token-link/' + res.data;
          const el = document.createElement('textarea');
          el.value = this.link;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.copyToaster();
        }
      });
  }

  confirmMove(event): void {
    if (event) {
      this.subject.next(event);
    }
    this.showDublicatePopup = false;
  }

  sumAmionth(): void {
    this.selecdetPrice = 0;
    this.scuare = 0;
    if (this.cadItems.length) {
      const sum = [];
      const price = [];

      this.cadItems.forEach((el) => {
        if (
          el.checked &&
          el.cadItem.typeParcel !== 'Единое землепользование' &&
          el.cadItem.typeParcel !== 'Контуры МКЗУ'
        ) {
          if (el.cadItem.shareInTheRight) {
            const shareRight = el.cadItem.shareInTheRight.split('/');

            const tempNumber =
              (el.cadItem.cadastralCost * +shareRight[0]) / +shareRight[1];
            price.push({key: el.cadItem.key, value: tempNumber});
            // this.selecdetPrice += tempNumber;

            const tempArea =
              (el.cadItem.areaValue * +shareRight[0]) / +shareRight[1];
            // this.scuare += tempArea;
            sum.push({key: el.cadItem.key, value: tempArea});
          } else {
            // this.selecdetPrice += el.cadItem.cadastralCost;
            // this.scuare += el.cadItem.areaValue;

            price.push({key: el.cadItem.key, value: el.cadItem.cadastralCost});
            sum.push({key: el.cadItem.key, value: el.cadItem.areaValue});
          }
        } else if (
          el.checked &&
          (el.cadItem.typeParcel === 'Единое землепользование' ||
            el.cadItem.typeParcel === 'Контуры МКЗУ') &&
          !el.childItems.length
        ) {
          if (el.cadItem.shareInTheRight) {
            const shareRight = el.cadItem.shareInTheRight.split('/');

            const tempNumber =
              (el.cadItem.cadastralCost * +shareRight[0]) / +shareRight[1];
            // this.selecdetPrice += tempNumber;
            price.push({key: el.cadItem.key, value: tempNumber});

            const tempArea =
              (el.cadItem.areaValue * +shareRight[0]) / +shareRight[1];
            // this.scuare += tempArea;
            sum.push({key: el.cadItem.key, value: tempArea});
          } else {
            // this.selecdetPrice += el.cadItem.cadastralCost;
            // this.scuare += el.cadItem.areaValue;
            price.push({key: el.cadItem.key, value: el.cadItem.cadastralCost});
            sum.push({key: el.cadItem.key, value: el.cadItem.areaValue});
          }
        } else {
          el.childItems.forEach((child) => {
            if (child.checked) {
              if (el.cadItem.shareInTheRight) {
                const shareRight = el.cadItem.shareInTheRight.split('/');

                const tempNumber =
                  (child.cadItem.cadastralCost * +shareRight[0]) /
                  +shareRight[1];
                // this.selecdetPrice += tempNumber;
                price.push({key: child.cadItem.key, value: tempNumber});
                const tempArea =
                  (child.cadItem.areaValue * +shareRight[0]) / +shareRight[1];
                // this.scuare += tempArea;
                price.push({key: child.cadItem.key, value: tempArea});
              } else {
                // this.selecdetPrice += child.cadItem.cadastralCost;
                // this.scuare += child.cadItem.areaValue;
                price.push({key: child.cadItem.key, value: child.cadItem.cadastralCost});
                sum.push({key: child.cadItem.key, value: child.cadItem.areaValue});
              }
            }
          });
        }
      });

      // removing leading zeros from keys
      price.forEach(el => {
        el.key = el.key.split(':').map(keyUnit => isNaN(+keyUnit) ? keyUnit : +keyUnit).join(':');
      });
      sum.forEach(el => {
        el.key = el.key.split(':').map(keyUnit => isNaN(+keyUnit) ? keyUnit : +keyUnit).join(':');
      });

      this.selecdetPrice = Object.values(this.groupBy(price, 'key')).map(x => x[0].value).reduce((a, b) => a + b, 0);
      this.scuare = Object.values(this.groupBy(sum, 'key')).map(x => x[0].value).reduce((a, b) => a + b, 0);

    }
  }

  groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  screenshot(): void {
    !this.homeService.burgerOpen ? document.getElementById('burgerOpenToggleButton').classList.add('hide') : true;
    document.getElementById('showScreenshotBut').classList.add('hide');
    document.getElementById('copylinkBut').classList.add('hide');
    document.getElementById('location-for-screenshot').classList.add('hide');
    document.getElementById('action-wrapper').classList.add('hide');
    html2canvas(document.getElementById('map'), {
      allowTaint: true,
      useCORS: true,
      logging: false,
      scale: 1,
    }).then((canvas) => {
      saveAs(canvas.toDataURL('image/png'), 'map image.png');
      !this.homeService.burgerOpen ? document.getElementById('burgerOpenToggleButton').classList.remove('hide') : true;
      document.getElementById('showScreenshotBut').classList.remove('hide');
      document.getElementById('copylinkBut').classList.remove('hide');
      document.getElementById('location-for-screenshot').classList.remove('hide');
      document.getElementById('action-wrapper').classList.remove('hide');
    });
  }

  removeOverlay(item): void {
    let pkks = JSON.parse(sessionStorage.getItem('pkks'));
    pkks = pkks.filter(p => p.keys != item.cadItem.key);
    sessionStorage.setItem('pkks', JSON.stringify(pkks));

    this.isDrawnSVG ? item.overlay.onRemove() : this.deleteItemFromMap(item);

    if (item.childItems.length) {
      item.childItems.forEach((element) => {
        this.isDrawnSVG ? element.overlay.onRemove() : this.deleteItemFromMap(element);
      });
    }
  }

  deleteItemFromMap(item: ICadItems) {
    switch (this.currentMap) {
      case mapHost.Google:
        this.toggleOnGoogle(item, false);
        break;
      case mapHost.Yandex:
        this.toggleOnYandex(item, false);
        break;
      case mapHost.OpenStreet:
        this.toggleOnOpenStreet(item, false);
        break;
    }
    item.drawedObjects = [];
  }

  searchCadNumber(): void {
    this.resetLoadedConditions();
    this.clearMap();

    this.currentMap = mapHost.Google;
    this.clearGeometryCalcs();

    const newCadList = [];
    let lines = this.cadSearch
      .trim()
      .split(/\r\n|\n\r|\n|\r|,| /);
    lines = lines.filter(x => x !== '');
    if (!lines || lines.length === 0) {
      return;
    }
    lines = lines.map(l => {
      return l.replace(/^0+/, '');
    });
    this.cadSearch = lines.toString();

    this.zoomEvent
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((e) => {
          if (!this.isDrawnSVG) {
            return;
          }
          if (!this.isSubscribedToBoundChange) {
            // this.map.googleMap.addListener('dragend', () => this.loadRedLines());
            // this.map.googleMap.addListener('zoom_changed', () => this.loadRedLines());
            this.map.googleMap.addListener('tilesloaded', () =>
              this.loadTilesAdditionData()
            );
            this.isSubscribedToBoundChange = true;
          }
          this.cadItems.forEach((el) => {
            if (el.cadItem.typeParcel !== 'Контуры МКЗУ') {
              el.overlay?.onChangeBorderWidth(e === 0 ? 3 : e);
            }
            if (el.childItems.length) {
              el.childItems.forEach((el) => {
                el.overlay?.onChangeBorderWidth(e === 0 ? 3 : e);
              });
            }
          });
        })
      )
      .subscribe();

    lines.forEach(x => newCadList.push({keys: x}));

    this.cadNumbers = newCadList;

    this.loadingPercent();
    if (this.isDrawnSVG) {
      this.cadItems.forEach((element) => {
        element.overlay.toggleDOM(this.map);
        element.childItems?.forEach((child) => {
          child.overlay.toggleDOM(this.map);
        });
      });
    }
    this.homeService.pkkKeys = this.cadNumbers;
    sessionStorage.setItem('pkks', JSON.stringify(this.cadNumbers));
    this.loadData();
  }

  clearGeometryCalcs() {
    this.geometryCalcs.distance = 0;
    this.geometryCalcs.area = 0;
    this.geometryCalcs.hectare = 0;
  }

  loadingPercent(): void {

    this.showLoading = true;

  }

  closePopup(): void {
    this.itemMovePopup = false;
  }


  moveToFolder(): void {
    this.KeyList = '';
    if (this.homeService.folders.length === 0) {
      this.createFolderPopup = true;
    }
    this.selectedCadItems = [];
    this.cadItems.forEach((el) => {
      if (el.checked) {
        this.selectedCadItems.push(el.cadItem.id);
        this.KeyList = this.KeyList + el.cadItem.key + ', ';
      }
      if (el.open && el.childItems.length) {
        el.childItems.forEach((child) => {
          if (child.checked) {
            this.selectedCadItems.push(child.cadItem.id);
            this.KeyList = this.KeyList + child.cadItem.key + ', ';
          }
        });
      }
    });
    if (this.selectedCadItems.length) {
      this.itemMovePopup = true;
    }
  }

  onMoveEvent(folderdata): void {

    this.toFilderName = folderdata.name;
    this.itemMovePopup = false;
    if (folderdata.id !== null) {
      const data: IitemMove = {
        pkkIds: this.selectedCadItems,
      };
      if (folderdata.id !== 0) {
        data.folderId = folderdata.id;
      }
      const chackData = {
        folderId: folderdata.id === 0 ? null : folderdata.id,
        pkkIds: this.selectedCadItems,
        clientId: +localStorage.getItem('userId'),
      };
      this.homeService.getExistPkksOnFolder(chackData).subscribe((res) => {
        if (res.data.length == 0) {
          this.homeService.addToFolder(data).subscribe((res) => {
            this.openToaster();
            this.homeService.getFolders();
          });
        } else {
          this.tempdata = data;
          this.showDublicatePopup = true;
        }
      });
    }
  }

  popUpConfirmed(isConfirmed): void {
    if (isConfirmed) {
      this.homeService.addToFolder(this.tempdata).subscribe((res) => {
        this.openToaster();
        this.homeService.getFolders();
      });
    }
  }

  parentCheched(parentId, child, event): void {
    child.checked = event.target.checked;

    const childPos = this.cadCenterPositions.find(x => x.key == child.cadItem.key);
    if (childPos) {
      childPos.isSelected = event.target.checked;
    }

    let checkParent = true;
    const parent = this.cadItems.find((el) => el.cadItem.id == parentId);
    parent.childItems?.forEach((element) => {
      if (!element.checked) {
        checkParent = false;
      }
    });
    parent.checked = checkParent;
    this.sumAmionth();
  }

  goToCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.currentPosition = {
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            // not used
            // label: {
            //   color: 'red',
            //   text: 'Marker label ',
            // },
            // title: 'Marker title ',
            options: {animation: google.maps.Animation.DROP, icon: '/assets/images/google-maps-circle-icon.png'},
          };

          if (this.currentMap == mapHost.Google) {
            this.map.googleMap.setCenter(pos);
          }

          if (this.currentMap == mapHost.Yandex) {
            const coordinates = [position.coords.latitude, position.coords.longitude];

            this.yandexMap.geoObjects.add(new ymaps.Placemark(coordinates, {}, {
              iconLayout: 'default#image',
              iconImageHref: '/assets/images/google-maps-circle-icon.png',
              iconImageSize: [32, 32],
            }));
            this.yandexMap.setCenter(coordinates);
          }

          if (this.currentMap == mapHost.OpenStreet) {
            const coords = ol.proj.fromLonLat([position.coords.longitude, position.coords.latitude]);

            const iconFeature = new ol.Feature({geometry: new ol.geom.Point(coords)});

            iconFeature.set('style', new ol.style.Style({
              image: new ol.style.Icon({
                src: '/assets/images/google-maps-circle-icon.png',
                imgSize: [32, 32]
              }),
            }));

            this.openStreetMap.addLayer(
              new ol.layer.Vector({
                style: (feature) => {
                  return feature.get('style');
                },
                source: new ol.source.Vector({features: [iconFeature]}),
              })
            );
            this.openStreetMap.getView().setCenter(coords);
          }
        },
        () => {
          alert('Error: The Geolocation service failed.');
        }
      );
    } else {
      alert('Error: Your browser doesn\'t support geolocation.');
    }
  }

  toggleChilds(parent, event): void {
    const parentPos = this.cadCenterPositions.find(x => x.key == parent.cadItem.key);
    if (parentPos) {
      parentPos.isSelected = event.target.checked;
    }

    parent.childItems.forEach((child) => {
      if (this.isDrawnSVG && child.overlay) {
        event.target.checked ? child.overlay.show() : child.overlay.hide();
      }

      if (this.isDrawnByCoords) {
        switch (this.currentMap) {
          case mapHost.Google:
            this.toggleOnGoogle(child, event.target.checked);
            break;
          case mapHost.Yandex:
            this.toggleOnYandex(child, event.target.checked);
            break;
          case mapHost.OpenStreet:
            this.toggleOnOpenStreet(child, event.target.checked);
            break;
        }
      }
      child.checked = event.target.checked;
      const childPos = this.cadCenterPositions.find(x => x.key == child.cadItem.key);
      if (childPos) {
        childPos.isSelected = event.target.checked;
      }
    });
    this.sumAmionth();
  }

  drawOverlay(bounds, image, svg, color?, item = null): USGSOverlay {
    const overlay = new USGSOverlay(bounds, image, svg, color, item);
    overlay.setMap(this.map.googleMap);
    this.changeDetectorRef.detectChanges();
    return overlay;
  }

  async getChilds(item) {
    if (
      item.cadItem.typeParcel === 'Единое землепользование' ||
      item.cadItem.typeParcel === 'Контуры МКЗУ'
    ) {
      // item.showChilds = true;
      item.cadItem.sumAreaValue = 0;
      const res = await this.homeService.getPkksByRegion([item.cadItem.key]);

      res.data.map((element) => {
        item.cadItem.sumAreaValue += +element.areaValue;
        return item.childItems.push({
          cadItem: element,
          overlay: null,
          bound: null,
          checked: true,
        });
      });
      this.sumAmionth();
      this.fillChildDataFromMap(item);
    }
  }

  openToaster(): void {
    this.toaster = true;
    setTimeout(() => {
      this.toaster = false;
    }, 3000);
  }

  copyToaster(): void {
    this.copyToas = true;
    setTimeout(() => {
      this.copyToas = false;
    }, 5000);
  }

  zoomChange(): void {
    // min zoom 0 ||  max zoom  22
    const zoom = this.map.googleMap.getZoom();
    const bordewidth = (17 - zoom) * 3;
    this.zoomEvent.next(bordewidth);
  }

  async loadData() {
    this.isAllCadItemsLoaded = false;
    await this.getPkkIds();
    this.isAllCadItemsLoaded = true;

  }

  async getPkkIds() {

    let isAlreadyCreated = false;
    if (this.homeService.pkkKeys && this.homeService.pkkKeys.length > 0) {
      this.cadItems = [];
      const res = await this.homeService.getpkk(this.homeService.pkkKeys);

      if (res.data) {
        res.data.map((element) => {
          return this.cadItems.push({
            cadItem: element,
            overlay: null,
            bound: null,
            checked: true,
            childItems: [],
          });
        });
        if (!this.homeService.pkkKeys[0].folderId && !isAlreadyCreated) {
          isAlreadyCreated = true;
          if (!this.homeService.pkkFolderKeys || this.homeService.pkkFolderKeys.length == 0) {
            this.fillDataFromMap();
          }
        }
        // this.sumAmionth();
      }
      if (!isAlreadyCreated) {
        isAlreadyCreated = true;
      }
      // await this.getPkksFolderOpts();
      // for (let index = 0; index < this.cadItems.length; index++) {
      //   const element = this.cadItems[index];
      //   await this.getChilds(element);
      // }
      if (this.homeService.pkkFolderKeys && this.homeService.pkkFolderKeys.length > 0) {
        for (const element of this.cadItems) {
          const folderProp = this.homeService.pkkFolderKeys?.find((x) => {
            return x.pkkId == element.cadItem.id;
          });
          Object.assign(element.cadItem, folderProp);
          await this.getChilds(element);
        }
        this.sumAmionth();
        this.fillDataFromMap();
      } else {
        await this.getPkksFolderOpts();
        for await (const element of this.cadItems) {
          try {
            await this.getChilds(element);
          } catch (e) {
            console.log('aaaaa: ', e);
          }
        }


        this.sumAmionth();
      }
    }
    this.showLoading = false;
  }

  async getPkksFolderOpts() {
    if (
      this.homeService.pkkKeys &&
      this.homeService.pkkKeys.length > 0 &&
      this.homeService.pkkKeys[0].pkkId
      // this.homeService.pkkKeys[0].folderId
    ) {
      const data = {
        ...(this.homeService.pkkKeys[0].folderId && {FolderId: this.homeService.pkkKeys[0].folderId}),
        // FolderId: this.homeService.pkkKeys[0].folderId ?? null,
        PkkIds: [],
      };
      this.homeService.pkkKeys.forEach((element) => {
        data.PkkIds.push(element.pkkId);
      });
      await this.homeService.getPkkFolderOptions(data).then((res) => {
        this.cadItems.forEach((element) => {
          const folderProp = res.data?.find((x) => {
            return x.pkkId == element.cadItem.id;
          });
          Object.assign(element.cadItem, folderProp);
        });
        this.sumAmionth();
        this.fillDataFromMap();
      });
    }
  }

  findLastCheckedElement(): ICadItems {
    for (let i = this.cadItems.length - 1; i >= 0; i--) {
      const element = this.cadItems[i];
      const childElement = element.childItems
        .slice()
        .reverse()
        .find((x) => x.checked);

      if (childElement != null) {
        return childElement;
      }
      if (element.checked) {
        return element;
      }
    }
    return null;
  }

  moveToElement(element = null): void {
    if (this.cadItems && element == null) {
      element = this.findLastCheckedElement();
    }
    if (element != null) {
      if (element && element.childItems && element.childItems.length > 0) {
        element = element.childItems[0];
      }
      const elementMins = this.meters2degress(
        element.cadItem.xmin,
        element.cadItem.ymin
      );
      const elementmaxs = this.meters2degress(
        element.cadItem.xmax,
        element.cadItem.ymax
      );

      switch (this.currentMap) {
        case mapHost.Google:
          this.moveToElementGoogle(elementMins, elementmaxs);
          break;
        case mapHost.Yandex:
          this.moveToElementYandex(element);
          break;
        case mapHost.OpenStreet:
          this.moveToElementOpenStreet(elementMins, elementmaxs);
          break;
      }

    }

  }

  moveToElementGoogle(mins: any[], maxs: any[]): void {
    if (!this.map?.googleMap) {
      return;
    }

    const elementBound = new google.maps.LatLngBounds(
      new google.maps.LatLng(mins[1], mins[0]),
      new google.maps.LatLng(maxs[1], maxs[0])
    );

    setTimeout(() => this.map.fitBounds(elementBound));
  }

  moveToElementYandex(element: ICadItems): void {
    if (!this.yandexMap) {
      return;
    }
    const bound = element.drawedObjects[0]?.geometry.getBounds() || element.bound;

    if (bound) {
      this.yandexMap.setBounds(bound, {duration: 500});
    }
  }

  moveToElementOpenStreet(mins: any[], maxs: any[]): void {
    if (!this.openStreetMap) {
      return;
    }

    const minCoords = [mins[0], mins[1]];
    const maxCoords = [maxs[0], maxs[1]];

    let extent = ol.extent.boundingExtent([minCoords, maxCoords]);
    extent = ol.proj.transformExtent(extent, ol.proj.get('EPSG:4326'), ol.proj.get('EPSG:3857'));

    this.openStreetMap.getView().fit(extent, {
      size: this.openStreetMap.getSize(),
      duration: 500
    });
  }

  moveToCurrentCenter() {
    if (this.currentMap === mapHost.Google) {
      this.map.googleMap.setCenter({
        lat: this.prevMapCenter[0],
        lng: this.prevMapCenter[1]
      });
      this.map.googleMap.setZoom(this.prevMapZoom);
      return;
    }
    if (this.currentMap === mapHost.Yandex) {
      const coords = this.prevMapCenter[0] ? [this.prevMapCenter[0], this.prevMapCenter[1]]
        : [this.prevMapCenter.lat(), this.prevMapCenter.lng()];
      this.yandexMap.setCenter(coords);
      this.yandexMap.setZoom(this.prevMapZoom);
    }
    if (this.currentMap === mapHost.OpenStreet) {
      const lonLat = this.prevMapCenter[0] ? [this.prevMapCenter[1], this.prevMapCenter[0]]
        : [this.prevMapCenter.lng(), this.prevMapCenter.lat()];
      this.openStreetMap.getView().setCenter(ol.proj.fromLonLat(lonLat));
      this.openStreetMap.getView().setZoom(this.prevMapZoom);
    }
  }

  fillChildDataFromMap(item): void {
    if (this.map?.googleMap || this.yandexMap || this.openStreetMap) {
      this.tempColor = this.getColor(item, true);
      item.childItems.map((child) => {
        if (child.checked) {
          if (child.cadItem.cordinats && !this.isDrawnSVG) {
            // Coordinates stores in server as JSON
            JSON.parse(child.cadItem.cordinats)
              .forEach(coords => this.drawPolyLinesByLatLang(coords, child));
            this.isDrawnByCoords = true;
          } else {
            this.drawSVGforElement(child, this.tempColor);
            this.isDrawnSVG = child.cadItem.imageSvg ? true : false;
          }
        }
      });
      if (this.isDrawnSVG) {
        this.isDrawnByCoords = false;
      }
      if (this.isDrawnByCoords) {
        this.moveToElement();
      }
    }
  }

  fillDataFromMap(): void {
    if (this.cadItems && (this.map?.googleMap || this.yandexMap || this.openStreetMap)) {
      this.cadItems.forEach((element) => {
        if (element.checked) {
          this.tempColor = this.getColor(element);
          if (element.cadItem.cordinats && !this.isDrawnSVG) {
            // Coordinates stores in server as JSON
            if (element.cadItem.typeParcel != 'Контуры МКЗУ') {
              JSON.parse(element.cadItem.cordinats)
                .forEach(coords => this.drawPolyLinesByLatLang(coords, element));
              this.isDrawnByCoords = true;
            }
          } else {
            this.drawSVGforElement(element, this.tempColor);
            this.isDrawnSVG = element.cadItem.imageSvg ? true : false;
          }
        }
      });
      this.changeDetectorRef.detectChanges();
      if (this.isDrawnSVG) {
        this.isDrawnByCoords = false;
      }
      if (this.isDrawnByCoords) {
        this.moveToElement();
      }
    }
  }

  getColor(element: ICadItems, isChild: boolean = false): string {
    if (isChild) {
      return this.colors.find((x) => x.id == element.cadItem.colorAreaId)?.rgb;
    }
    return element.cadItem.colorAreaId ? this.colors[element.cadItem.colorAreaId - 1].rgb : null;
  }

  drawSVGforElement(element: ICadItems, rgbColor) {
    if (element.cadItem.cordinats) {
      this.renderChildPolygonOnMap(element.cadItem);
      return;
    }
    const elementMins = this.meters2degress(
      element.cadItem.xmin,
      element.cadItem.ymin
    );
    const elementmaxs = this.meters2degress(
      element.cadItem.xmax,
      element.cadItem.ymax
    );
    const elementBound = new google.maps.LatLngBounds(
      new google.maps.LatLng(elementMins[1], elementMins[0]),
      new google.maps.LatLng(elementmaxs[1], elementmaxs[0])
    );
    this.map.fitBounds(elementBound);
    const elementOverlay = this.drawOverlay(
      elementBound,
      element.cadItem.imageBase64 ? 'data:image/png;base64,' + element.cadItem.imageBase64 : '',
      element.cadItem.imageSvg ? 'data:image/svg+xml;utf8,' + element.cadItem.imageSvg : null,
      rgbColor,
      element.cadItem
    );
    element.bound = elementBound;
    element.overlay = elementOverlay;
  }

  onTileLoaded() {
    if ((this.isAllComputed || this.isDrawnByCoords) && this.isAllCadItemsLoaded) {
      return;
    }

    if (this.isAllCadItemsLoaded) {
      // if cadItem or childCadItem doesn't have computed coordinates yet
      // compute them and send to server as JSON
      this.cadItems.forEach(item => {
        if (!item.cadItem.cordinats && item.overlay?.div && item.cadItem.imageSvg) {
          const coordinates = JSON.stringify(this.computeSVGPathsCoords(item));
          const mapItem = new google.maps.Polygon({
            paths: JSON.parse(coordinates),
            strokeColor: 'rgb(255,255,0)',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: 'rgb(255,255,0)',
            fillOpacity: 0,
          });
          mapItem.setMap(this.map.googleMap);
          this.cad.updateCoordinates({
            key: item.cadItem.key,
            cordinats: coordinates
          });

          item.cadItem.cordinats = coordinates;
          item.mapItem = mapItem;
        }

        if (item.childItems.length) {
          item.childItems.forEach(childItem => {
            if (!childItem.cadItem.cordinats && childItem.overlay.div && childItem.cadItem.imageSvg) {
              const childCoordinates = JSON.stringify(this.computeSVGPathsCoords(childItem));
              const mapItem = new google.maps.Polygon({
                paths: JSON.parse(childCoordinates),
                strokeColor: 'rgb(255,255,0)',
                strokeOpacity: 0.5,
                strokeWeight: 2,
                fillColor: 'rgb(255,255,0)',
                fillOpacity: 0,
              });
              mapItem.setMap(this.map.googleMap);
              this.cad.updateCoordinates({
                key: childItem.cadItem.key,
                cordinats: childCoordinates
              });

              childItem.cadItem.cordinats = childCoordinates;
              childItem.mapItem = mapItem;
            }
          });
        }
      });
      this.isAllComputed = true;
    }
  }

  redrawPolyLinesOnMap(): void {
    this.isDrawnSVG = false;
    // Coordinates stores in server as JSON
    this.cadItems.forEach(item => {
      if (item.mapItem) {
        item.mapItem = null;
      }
      item.drawedObjects = [];
      if (item.cadItem.cordinats && item.cadItem.typeParcel != 'Контуры МКЗУ') {
        this.tempColor = this.getColor(item);
        JSON.parse(item.cadItem.cordinats).forEach(coords => this.drawPolyLinesByLatLang(coords, item));
      }
      if (item.childItems.length) {
        item.childItems.forEach(childItem => {
          if (childItem.mapItem) {
            childItem.mapItem = null;
          }
          childItem.drawedObjects = [];
          if (childItem.cadItem.cordinats) {
            this.tempColor = this.getColor(item, true);
            JSON.parse(childItem.cadItem.cordinats)
              .forEach(coords => this.drawPolyLinesByLatLang(coords, childItem));
          }
        });
      }
    });
    this.isDrawnByCoords = true;
    this.moveToCurrentCenter();
  }

  clearMap(): void {
    if (this.currentMap == mapHost.Google) {
      this.cadItems.forEach(item => {
        if (item.drawedObjects?.length) {
          item.drawedObjects.forEach(obj => obj.setMap(null));
        }

        if (item.childItems.length) {
          item.childItems.forEach(childItem => {
            if (childItem.drawedObjects?.length) {
              childItem.drawedObjects.forEach(obj => obj.setMap(null));
            }
          });
        }
      });
    }
    this.isDrawnByCoords = false;
  }

  computeSVGPathsCoords(item: ICadItems): LatLng[][] {
    const svg = item.cadItem.imageSvg;

    const svgSize = this.getSVGSizes(svg);
    const svgPaths = this.getSVGPaths(svg);

    const scaleX = item.overlay.div.clientWidth / svgSize.width;
    const scaleY = item.overlay.div.clientHeight / svgSize.height;

    console.log('item, scaleY , scaleX:  ', item, scaleY, scaleX)
    const svgCoords = this.getSVGCoordsByPaths(svgPaths);
    let scale;
    const computedSvgCoords = svgCoords.map(points => {
      if (!points.isChild) {
        console.log(points.coords[1], scaleY, scaleX);
        scale = svgCoords[0].coords[1] == 0 ? scaleY : scaleX;
      }
      points.coords = points.coords.map(point => point * scale);
      return points;
    });

    return this.getPolylineLatLngBySVGCoords(computedSvgCoords, item.overlay);
  }

  getSVGSizes(svg: string): { width: number, height: number } {
    const svgWidthIndex = svg.indexOf('width=\"') + 7;
    const svgHeighthIndex = svg.indexOf('height=\"') + 8;

    return {
      width: +svg.slice(svgWidthIndex, svg.indexOf('\"', svgWidthIndex)),
      height: +svg.slice(svgHeighthIndex, svg.indexOf('\"', svgHeighthIndex))
    };
  }

  getSVGPaths(svg: string): string[] {
    const svgPaths = [];
    svg.split('<path').forEach((path, index) => {
      if (index) {
        const svgPathIndex = path.indexOf('d=\"') + 3;
        const svgPath = path.slice(svgPathIndex, path.indexOf('\"', svgPathIndex));

        svgPaths.push(svgPath);
      }
    });
    return svgPaths;
  }

  getSVGCoordsByPaths(paths: string[]): { coords: number[], isChild: boolean }[] {
    const coordinates = [];
    paths.forEach(path => {
      path.split('Z M').forEach((splitedPath, index) => {
        coordinates.push({
          coords: splitedPath.match(/(\d+\.\d*)|(\d+)/g),
          isChild: index ? true : false
        });
      });
    });

    return coordinates;
  }

  getMinimalPoints(array: number[]): { x: number, y: number } {
    const minimalPoint: any = {
      x: array[0],
      y: array[1],
    };
    for (let i = 0; i < array.length - 1; i += 2) {
      if (minimalPoint.x > array[i]) {
        minimalPoint.x = array[i];
      }
    }
    for (let i = 1; i < array.length - 1; i += 2) {
      if (minimalPoint.y > array[i]) {
        minimalPoint.y = array[i];
      }
    }
    return minimalPoint;
  }

  getPolylineLatLngBySVGCoords(svgCoords: { coords: number[], isChild: boolean }[], overlay: USGSOverlay): LatLng[][] {
    const overlayProjection = overlay.getProjection();
    const leftOffset = parseFloat(overlay.div.style.left);
    const topOffset = parseFloat(overlay.div.style.top);

    const figures = [];
    let minCoord;
    // @ts-ignore
    window.map = this.map;
    // @ts-ignore
    window.overlay = overlay;
    console.log('AAAAAAAAAAAAAAAAAAAAA', overlay.getProjection().fromLatLngToContainerPixel(this.map.getCenter()));
    // @ts-ignore

    let min1 = [] || any;

    svgCoords.forEach(x => {
      min1 = [...min1, ...x.coords];
    });
    //debugger;
    min1 = this.getMinimalPoints(min1);


    svgCoords.forEach(svgCoord => {
      if (!svgCoord.isChild) {
        minCoord = this.getMinimalPoints(svgCoord.coords);
      }

      const polyCoordinates: LatLng[] = [];

      // Even - X coorditane, Odd - Y coordinate
      for (let i = 0; i < svgCoord.coords.length - 1; i += 2) {
        const coord: google.maps.LatLng = overlayProjection.fromDivPixelToLatLng(
          new google.maps.Point(
            svgCoord.coords[i] - min1.x + leftOffset,
            svgCoord.coords[i + 1] - min1.y + topOffset
          )
        );
        polyCoordinates.push({
          lat: coord.lat(),
          lng: coord.lng()
        });
      }
      figures.push(polyCoordinates);
    });
    return figures;
  }

  drawPolyLinesByLatLang(polyCoords: LatLng[], item: ICadItems): void {
    if (this.isDrawnSVG) {
      return;
    }
    if (!item.drawedObjects) {
      item.drawedObjects = [];
    }

    if (this.currentMap == mapHost.Google) {
      item.drawedObjects.push(
        this.drawPolylineOnGoogleMap(polyCoords)
      );
      return;
    }

    if (this.currentMap == mapHost.Yandex) {
      item.drawedObjects.push(
        this.drawPolylineOnYandexMap(polyCoords.map(coord => [coord.lat, coord.lng]))
      );
      return;
    }

    if (this.currentMap == mapHost.OpenStreet) {
      item.drawedObjects.push(
        this.drawLineOnOpenStreetMap(
          polyCoords.map(coord => ol.proj.fromLonLat([coord.lng, coord.lat]))
        )
      );
      return;
    }
  }

  drawPolylineOnGoogleMap(polyCoords: LatLng[]): google.maps.Polyline {
    const polylines = new google.maps.Polyline({
      path: polyCoords,
      strokeColor: this.tempColor || 'rgb(255, 255, 0)', // yellow
      strokeOpacity: 1,
      strokeWeight: 2,
    });

    polylines.setMap(this.map.googleMap);
    return polylines;
  }

  /**returns ymaps.Polyline */
  drawPolylineOnYandexMap(polyCoords: number[][]) {
    const polyline = new ymaps.Polyline(polyCoords, {}, {
      strokeColor: this.tempColor || 'rgb(255, 255, 0)', // yellow
      strokeWidth: 2,
      strokeOpacity: 1,
    });
    this.yandexMap.geoObjects.add(polyline);
    return polyline;
  }

  /**returns ol.layer.Vector */
  drawLineOnOpenStreetMap(lineCoords: number[][]) {
    const featureLine = new ol.Feature({
      geometry: new ol.geom.LineString(lineCoords)
    });
    const vectorLine = new ol.source.Vector({});
    vectorLine.addFeature(featureLine);

    const vectorLineLayer = new ol.layer.Vector({
      source: vectorLine,
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: this.tempColor || 'rgb(255, 255, 0)', // yellow
          width: 2
        })
      })
    });

    this.openStreetMap.addLayer(vectorLineLayer);
    return vectorLineLayer;
  }

  meters2degress(x, y): Array<any> {
    const lon = (x * 180) / 20037508.34;
    const lat =
      (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
    return [this.round(lon, 7), this.round(lat, 7)];
  }

  degrees2meters(lon, lat): Array<any> {
    const x = (lon * 20037508.34) / 180;
    let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180;
    return [x, y];
  }

  round(value, precision): any {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  async mapChange() {
    this.prevMapCenter = this.map?.googleMap.getCenter() ||
      this.yandexMap.getCenter() ||
      this.openStreetMap.getCenter();
    this.prevMapZoom = this.map?.googleMap.getZoom() ||
      this.yandexMap.getZoom() ||
      this.openStreetMap.getZoom();

    if (this.currentMap === mapHost.Google) {
      // for update ViewChild
      this.isSubscribedToBoundChange = false;
      this.addMainZoomEventOnGoogleMaps();
      this.changeDetectorRef.detectChanges();
      this.redrawPolyLinesOnMap();
    }

    if (this.currentMap === mapHost.Yandex) {
      await ymaps.ready();
      this.yandexMap = new ymaps.Map('yandexMap', {
          zoom: 12,
          center: [55.75490171455126, 37.62086691244819],
          controls: [
            new ymaps.control.ZoomControl({
              options: {
                size: 'saml',
                position: {bottom: 50, right: 20}
              }
            })
          ]
        },
        {autoFitToViewport: 'always'});
      this.yandexMap.controls.add('rulerControl', {
        position: {top: 50, right: 100},
        scaleLine: false
      });

      this.setYandexMapType();

      this.redrawPolyLinesOnMap();
    }

    if (this.currentMap === mapHost.OpenStreet) {
      setTimeout(() => {
        this.openStreetMap = new ol.Map({
          target: 'openStreetMap',
          layers: [
            new ol.layer.Tile({
              source: new ol.source.OSM({
                url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              })
            }),
            new ol.layer.Tile({
              visible: false,
              source: new ol.source.BingMaps({
                key: 'Alyb_nxW-1g2r31Mgjnx_uPMmaRIwJCUBJK3jtcYTbfR2WnpvHBVZNBdA3j915B0',
                imagerySet: 'Aerial'
              })
            }),
            new ol.layer.Tile({
              visible: false,
              source: new ol.source.BingMaps({
                key: 'Alyb_nxW-1g2r31Mgjnx_uPMmaRIwJCUBJK3jtcYTbfR2WnpvHBVZNBdA3j915B0',
                imagerySet: 'AerialWithLabelsOnDemand'
              })
            }),
          ],
          view: new ol.View({
            center: ol.proj.fromLonLat([37.62086691244819, 55.75490171455126]),
            zoom: 12
          })
        });
        this.setOpenStreetMapType();
        this.redrawPolyLinesOnMap();
      });
    }
  }

  viewChange(type: string, name: string): void {
    this.mapTypeId = type;
    this.mapTypeName = name;
    this.maptypeDropdownHandler();

    if (this.currentMap === mapHost.Yandex) {
      this.setYandexMapType();
    }
    if (this.currentMap === mapHost.OpenStreet) {
      this.setOpenStreetMapType();
    }
  }

  setYandexMapType() {
    this.yandexMap.setType(`yandex#${this.mapTypeId == 'roadmap' ? 'map' : this.mapTypeId}`);
  }

  setOpenStreetMapType() {
    let selectedTypeIndex;
    if (this.mapTypeId === 'roadmap') {
      selectedTypeIndex = 0;
    }
    if (this.mapTypeId === 'satellite') {
      selectedTypeIndex = 1;
    }
    if (this.mapTypeId === 'hybrid') {
      selectedTypeIndex = 2;
    }

    const layers = this.openStreetMap.getAllLayers();
    for (let i = 0; i < 3; i++) {
      layers[i].setVisible(i == selectedTypeIndex);
    }
  }

  createFolderClose(event): void {
    this.createFolderPopup = false;
  }

  //#region Red Lines

  loadTilesAdditionData() {
    this.layers.forEach(l => {
      l.overlays.map((x) => x.onRemove());
      l.overlays = [];
    });

    let zoomLevel = this.map.getZoom();
    const tiles = this.tileHelper.getAllTiles(this.map, zoomLevel);


    if (this.layers[LayerTypes.Border].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Border);
    }

    if (this.layers[LayerTypes.Zone].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Zone);
    }

    if (this.layers[LayerTypes.Territory].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Territory);
    }

    if (this.layers[LayerTypes.SpecialZone].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.SpecialZone);
    }

    if (this.layers[LayerTypes.Building].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Building);
    }

    if (this.layers[LayerTypes.Union].isEnabled) {
      this.loadLayersWith256(tiles, LayerTypes.Union);
    }


    if (this.layers[LayerTypes.Land].isEnabled) {
      if (zoomLevel > 12) {
        if (zoomLevel > 16) {
          zoomLevel = 16;
        }

        if (zoomLevel < 16) {
          this.loadLayersWith256(tiles, LayerTypes.Land);
        } else {
          this.loadLayersWith1024(tiles, zoomLevel, LayerTypes.Land);
        }
      }
    }


  }

//    async loadVectoredRedLines(tiles, zoomLevel){
//     const layers = [];
//     for (let index = 0; index < 1; index++) {
//        const tile = tiles[index];

//        let layer = new TileLayer({
//         stroked: false,

//         getLineColor: f => {
//           var color;
//           let pit = f.properties.pit
//           if (pit <= 0.13) {
//             color = [0, 255, 0]
//           } else if (pit <= 0.28) {
//             color = [255, 255, 0]
//           } else if (pit > 0.28) {
//             color = [255, 0, 0]
//           } else {
//             color = [0, 0, 0]
//           }
//           return [0, 0, 0]
//         },

//         getLineWidth: f => {
//           return 3;
//         },
//         lineWidthMinPixels: 1,

//         getTileData: () => {
//           const mapSource = `https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/tile/${tile.z}/${tile.y}/${tile.x}.pbf`;
//           return fetch(mapSource)
//             .then(response => response.arrayBuffer())
//             .then(buffer => {
//               const til = new VectorTile(new Protobuf(buffer));
//               const features = [];
//               for (const layerName in til.layers) {
//                 const vectorTileLayer = til.layers[layerName];
//                 for (let i = 0; i < vectorTileLayer.length; i++) {
//                   const vectorTileFeature = vectorTileLayer.feature(i);
//                   const feature = vectorTileFeature.toGeoJSON(tile.x, tile.y, tile.z);
//                   features.push(feature);
//                 }
//               }
//               this.map.data.loadGeoJson(features);
//               return features;
//             });
//         }
//       });


//       layers.push(layer);

//   }

//   let overlay = new GoogleMapsOverlay({
//     layers: [layers]
//   });

//   overlay.setMap(this.map.googleMap);
// }

  public blobToData = (blob: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  // removeRedLins() {
  //   this.redLinesOverlays.map((x) => x.onRemove());
  //   this.redLinesOverlays = [];
  // }

  removePkkLayers(type) {
    this.layers[type].overlays.map((x) => x.onRemove());
    this.layers[type].overlays = [];
  }

  loadLayersWith256(tiles, type: LayerTypes) {
    tiles.map((tile) => {
      const latLng = this.tileHelper.getTileBounds(tile);

      const ne = new google.maps.LatLng(latLng.ne.lat, latLng.ne.lng);

      const sw = new google.maps.LatLng(latLng.sw.lat, latLng.sw.lng);
      const newbounds = new google.maps.LatLngBounds(sw, ne);

      this.loadLayers(newbounds, type);
    });
  }

  loadLayersWith1024(tiles, zoomLevel, type: LayerTypes) {
    let xs = [];
    let ys = [];

    tiles.map((tile) => {
      xs.push(tile.x);
      ys.push(tile.y);
    });

    xs = xs.filter((v, i, a) => a.indexOf(v) === i);
    ys = ys.filter((v, i, a) => a.indexOf(v) === i);

    if (xs.length % 2 !== 0) {
      xs.push(xs[0] + 1);
    }
    if (ys.length % 2 !== 0) {
      ys.push(ys[0] - 1);
    }

    xs.sort((a, b) => a - b);
    ys.sort((a, b) => a - b);

    for (let i = 0; i < xs.length; i += 2) {
      for (let j = 0; j < ys.length; j += 2) {
        const first = this.tileHelper.getTileBounds({
          x: xs[i + 1],
          y: ys[j],
          z: zoomLevel,
        });
        const second = this.tileHelper.getTileBounds({
          x: xs[i],
          y: ys[j + 1],
          z: zoomLevel,
        });

        const ne = new google.maps.LatLng(first.ne.lat, first.ne.lng);

        const sw = new google.maps.LatLng(second.sw.lat, second.sw.lng);
        const newbounds = new google.maps.LatLngBounds(sw, ne);
        this.loadLayers(newbounds, type);
      }
    }
  }


  loadLayers(bounds, type: LayerTypes): void {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const maxs = this.degrees2meters(ne.lng(), ne.lat());
    const mins = this.degrees2meters(sw.lng(), sw.lat());

    let imageUrl = null;
    switch (type) {
      case LayerTypes.Land:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A30%2C27%2C24%2C23%2C22&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Border:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/BordersGKN/MapServer/export?layers=show%3A0%2C5%2C16%2C25%2C32&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Zone:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?layers=show%3A6%2C11&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Territory:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?layers=show%3A5&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.SpecialZone:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/ZONES/MapServer/export?layers=show%3A4%2C3%2C2%2C1&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Building:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A30&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;
      case LayerTypes.Union:
        imageUrl =
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A35&dpi=96&format=PNG32&bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false';
        imageUrl = imageUrl
          .replace('{xmin}', mins[0])
          .replace('{ymin}', mins[1])
          .replace('{xmax}', maxs[0])
          .replace('{ymax}', maxs[1]);
        break;

    }
    const overlay = this.drawOverlay(bounds, imageUrl, null);
    this.layers[type].overlays.push(overlay);
  }


  pkkLayersToggle(value, type) {
    this.layers[type].isEnabled = value;
    if (value) {
      this.loadTilesAdditionData();
    } else {
      this.removePkkLayers(type);
    }
  }

  addCenterMarkers(value) {
    this.centerMarkersEnabled = value;
    if (value) {
      this.cadItems.map(item => {
        if (item.childItems && item.childItems.length > 0) {
          item.childItems.forEach(child => {
            const coords = this.meters2degress(child.cadItem.xcenter, child.cadItem.ycenter);
            const contentString = `<div><p class="cad-number"> Номер  ${item.cadItem.key} </p><p class="show-on-text"> показать на </p><a target="_bank" class="google-nav-link" href="https://www.google.com/maps/search/?api=1&query=${coords[1]},${coords[0]}"><img class="external-map-icon" src="../../../assets/images/google-maps.svg"></a><a class="yandex-map-link" target="_blank" href="https://yandex.ru/maps/?pt=${coords[0]},${coords[1]}&z=18&l=map"><img class="external-map-icon" src="../../../assets/images/yandex_logo.svg"></a></div>`;
            this.cadCenterPositions.push({
              key: child.cadItem.key,
              isSelected: child.checked,
              position: {
                lat: coords[1],
                lng: coords[0],
              },
              // not used
              // label: {
              //   color: 'white',
              //   text: item.cadItem.key,
              // },
              // info: 'Номер ' + item.cadItem.key,
              info: {
                content: contentString,
              },
              // title: item.cadItem.key,
              options: {animation: google.maps.Animation.DROP, icon: '/assets/images/target-circles.svg'},
            });

          });
        } else {
          const coords = this.meters2degress(item.cadItem.xcenter, item.cadItem.ycenter);
          const contentString = `<div><p class="cad-number"> Номер  ${item.cadItem.key} </p><p class="show-on-text"> показать на </p><a target="_bank" class="google-nav-link" href="https://www.google.com/maps/search/?api=1&query=${coords[1]},${coords[0]}"><img class="external-map-icon" src="../../../assets/images/google-maps.svg"></a><a class="yandex-map-link" target="_blank" href="https://yandex.ru/maps/?pt=${coords[0]},${coords[1]}&z=18&l=map"><img class="external-map-icon" src="../../../assets/images/yandex_logo.svg"></a></div>`;
          this.cadCenterPositions.push({
            key: item.cadItem.key,
            isSelected: item.checked,
            position: {
              lat: coords[1],
              lng: coords[0],
            },
            // not used
            // label: {
            //   color: 'white',
            //   text: item.cadItem.key,
            // },
            info: {
              content: contentString
            },
            // title: item.cadItem.key,
            options: {animation: google.maps.Animation.DROP, icon: '/assets/images/target-circles.svg'},
          });
        }
      });
    } else {
      this.removeCenterMarkers();
    }
  }

  removeCenterMarkers() {
    this.cadCenterPositions = [];
  }

  openInfo(marker: MapMarker, content) {
    this.cadInfoOnMap = content;
    this.info.open(marker);
  }

  //#endregion

  getTagTypes(): void {
    this.homeService.getTypes().then((res) => {
      this.homeService.pkkTagTypes = res.data;
      this.tagTypes = [
        ...this.tagTypes,
        ...this.homeService.pkkTagTypes.reverse(),
      ];
    });
  }

  // map type dropdown

  maptypeDropdownHandler() {
    this.mapTypeDropdownIsOpened = !this.mapTypeDropdownIsOpened;
  }


  public handleOnClickGenInformation(child, showOverlay: boolean): void {
    child.open = true;
    if (!child.checked) {
      this.handleOverlayHide(child, false, false);
      return;
    }
    if (showOverlay) {
      if (child.overlay) {
        child.overlay.fill = true;
        child.overlay.onFill();
      } else {
        this.renderChildPolygonOnMap(child);
      }
    } else {
      this.renderChildPolygonOnMap(child);
    }
  }

  private renderChildPolygonOnMap(child) {
    if (this.currentMap === mapHost.Google && this.map && this.map.googleMap) {
      if (child.cadItem && child.cadItem.cordinats && child.checked) {
        const yCord = JSON.parse(child.cadItem.cordinats);
        if (child.mapItem && child.mapItem.setMap) {
          child.mapItem.setMap(null);
        }
        if (child.cadItem.mapItem && child.cadItem.mapItem.setMap) {
          child.cadItem.mapItem.setMap(null);
        }
        child.mapItem = this.drawPolygonOnGoogleMap(yCord);
      }
      if (child.cordinats) {
        const yCord = JSON.parse(child.cordinats);
        if (child.mapItem && child.mapItem.setMap) {
          child.mapItem.setMap(null);
        }
        if (child.cadItem && child.cadItem.mapItem && child.cadItem.mapItem.setMap) {
          child.cadItem.mapItem.setMap(null);
        }
        child.mapItem = this.drawPolygonOnGoogleMap(yCord);
      }
    }
    if (this.currentMap === mapHost.Yandex && this.yandexMap && child.checked) {
      if (child.cadItem && child.cadItem.cordinats) {
        const yCord = JSON.parse(child.cadItem.cordinats).map(x => x.map(y => [y.lat, y.lng]));
        if (child.mapItem) {
          this.yandexMap.geoObjects.remove(child.mapItem);
        }
        child.mapItem = this.drawPolygonOnYandexMap(yCord);
      }
      if (child.cordinats) {
        const yCord = JSON.parse(child.cordinats).map(x => x.map(y => [y.lat, y.lng]));
        if (child.mapItem) {
          this.yandexMap.geoObjects.remove(child.mapItem);
        }
        child.mapItem = this.drawPolygonOnYandexMap(yCord);
      }
    }
    if (this.currentMap === mapHost.OpenStreet && this.openStreetMap && child.checked) {
      if (child.cadItem && child.cadItem.cordinats && child.checked) {
        const polygonCoords = JSON.parse(child.cadItem.cordinats)[0].map(coord => ol.proj.fromLonLat([coord.lng, coord.lat]));
        if (child.mapItem) {
          this.openStreetMap.removeLayer(child.mapItem);
        }
        child.mapItem = this.drawPolygonOnOpenStreetMap(polygonCoords);
      }
      if (child.cordinats) {
        const polygonCoords = JSON.parse(child.cordinats)[0].map(coord => ol.proj.fromLonLat([coord.lng, coord.lat]));
        if (child.mapItem) {
          this.openStreetMap.removeLayer(child.mapItem);
        }
        child.mapItem = this.drawPolygonOnOpenStreetMap(polygonCoords);
      }
    }
  }

  public handleOverlayHide(child, withFill, needToClose = true): void {
    if (needToClose) {
      child.open = false;
      if (this.isDrawnSVG) {
        if (child.overlay) {
          child.overlay.fill = false;
          if (withFill) {
            child.overlay.onFill();
          }
        }
      }
    }
    if (child.mapItem) {
      if (this.currentMap === mapHost.Google && this.map && this.map.googleMap) {
        child.mapItem.setMap(null);
      }
      if (this.currentMap === mapHost.Yandex && this.yandexMap) {
        this.yandexMap.geoObjects.remove(child.mapItem);
      }
      if (this.currentMap === mapHost.OpenStreet && this.openStreetMap) {
        this.openStreetMap.removeLayer(child.mapItem);
      }
    }
  }

  public handleItemCheckboxClicked(item, $event): void {
    item.checked = !item.checked;

    item.overlay?.toggle();
    switch (this.currentMap) {
      case mapHost.Google:
        this.toggleOnGoogle(item, item.checked);
        break;
      case mapHost.Yandex:
        this.toggleOnYandex(item, item.checked);
        break;
      case mapHost.OpenStreet:
        this.toggleOnOpenStreet(item, item.checked);
        break;
    }

    this.toggleChilds(item, $event);
  }

  toggleChildItem(item, $event) {
    if (this.isDrawnSVG) {
      if (item && item.overlay && item.overlay.toggle) {
        item.overlay.toggle();
      }
    }

    if (this.isDrawnByCoords) {
      switch (this.currentMap) {
        case mapHost.Google:
          this.toggleOnGoogle(item, $event.target.checked);
          break;
        case mapHost.Yandex:
          this.toggleOnYandex(item, $event.target.checked);
          break;
        case mapHost.OpenStreet:
          this.toggleOnOpenStreet(item, $event.target.checked);
          break;
      }
    }
  }

  toggleOnGoogle(item, state: boolean) {
    if (item.mapItem && item.mapItem.setMap) {
      item.mapItem.setMap(state ? this.map.googleMap : null);
      if (!item.open) {
        item.mapItem.setMap(null);
      }
    }
    if (item.drawedObjects?.length) {
      item.drawedObjects?.forEach(obj => {
        if (obj.mapItem && obj.mapItem.setMap) {
          obj.mapItem.setMap(state ? this.map.googleMap : null);
          if (!obj.open) {
            obj.mapItem.setMap(null);
          }
        }
        obj.setMap(state ? this.map.googleMap : null);
      });
    }
  }

  toggleOnYandex(item, state: boolean) {
    if (item.mapItem && item.mapItem.options) {
      item.mapItem.options.set('visible', state);
      if (!item.open) {
        item.mapItem.options.set('visible', false);
      }
    }
    if (item.drawedObjects?.length) {
      item.drawedObjects?.forEach(obj => {
        if (obj.mapItem && obj.mapItem.options) {
          obj.mapItem.options.set('visible', state);
          if (!obj.open) {
            obj.mapItem.options.set('visible', false);
          }
        }
        obj.options.set('visible', state);
      });
    }
  }

  toggleOnOpenStreet(item, state: boolean) {
    if (item.mapItem && item.mapItem.setVisible) {
      item.mapItem.setVisible(state);
      if (!item.open) {
        item.mapItem.setVisible(false);
      }
    }
    if (item.drawedObjects?.length) {
      item.drawedObjects?.forEach(obj => {
        if (obj.mapItem && obj.mapItem.options) {
          obj.mapItem.setVisible(state);
          if (!obj.open) {
            obj.mapItem.setVisible(false);
          }
        }
        obj.setVisible(state);
      });
    }
  }

  resetLoadedConditions() {
    this.isAllCadItemsLoaded = false;
    this.isAllComputed = false;
  }

  ngOnDestroy(): void {
    this.homeService.getCalcCordinatSubject.next(null);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  isAllChildLoaded(item) {
    const isLoaded = item.cadItem.downloadException;
    if (isLoaded) {
      return 'rgba(255,0,0,0.5)';
    }
    if (!item.childItems || item.childItems.length === 0) {
      if (!(item.cadItem.imageBase64 || item.cadItem.imageSvg)) {
        return 'rgba(255,0,255,0.5)';
      }
      return '';
    }
    const isChildLoaded = item.childItems.some(z => z.cadItem.downloadException || !(z.cadItem.imageBase64 || z.cadItem.imageSvg));
    if (isChildLoaded) {
      return 'rgba(255,255,0,0.5)';
    }
    return '';
  }

  drawPolygonOnYandexMap(coordinates) {
    const polygon = new ymaps.Polygon(
      coordinates,
      {
        hintContent: 'Polygon'
      },
      {
        fillColor: 'rgba(255,255,0,0.5)',
        strokeColor: 'rgb(255,255,0)',
        strokeWidth: 2
      });
    this.yandexMap.geoObjects.add(polygon);
    return polygon;
  }

  drawPolygonOnGoogleMap(coordinates) {
    const polygon = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: 'rgb(255,255,0)',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: 'rgb(255,255,0)',
      fillOpacity: 0.5,
    });
    polygon.setMap(this.map.googleMap);
    return polygon;
  }

  drawPolygonOnOpenStreetMap(polygonCoords) {
    const polygon = new ol.geom.Polygon([polygonCoords]);
    const polygonFeature = new ol.Feature({
      geometry: polygon
    });
    const vectorLayer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [polygonFeature]
      }),
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: 'rgba(255,255,0, 0.8)',
          width: 2
        }),
        fill: new ol.style.Fill({
          color: 'rgba(255,255,0, 0.5)'
        })
      })
    });
    this.openStreetMap.addLayer(vectorLayer);
    return vectorLayer;
  }
}

interface LatLng {
  lat: number;
  lng: number;
}

export enum mapHost {
  Google = 'google',
  Yandex = 'yandex',
  OpenStreet = 'openstreetmap'
}

export enum LayerTypes {
  Land = 0,
  Border = 1,
  Zone = 2,
  Territory = 3,
  SpecialZone = 4,
  Building = 5,
  Union = 6
}
