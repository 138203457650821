export class TileHelper
{
  getAllTiles(map, zoom) {
    var bounds = map.getBounds(),
      boundsNeLatLng = bounds.getNorthEast(),
      boundsSwLatLng = bounds.getSouthWest(),
      boundsNwLatLng = new google.maps.LatLng(
        boundsNeLatLng.lat(),
        boundsSwLatLng.lng()
      ),
      boundsSeLatLng = new google.maps.LatLng(
        boundsSwLatLng.lat(),
        boundsNeLatLng.lng()
      ),
      tiles = [],
      tileCoordinateNw = this.pointToTile(map, boundsNwLatLng, zoom),
      tileCoordinateSe = this.pointToTile(map, boundsSeLatLng, zoom),
      tileColumns = tileCoordinateSe.x - tileCoordinateNw.x + 1,
      tileRows = tileCoordinateSe.y - tileCoordinateNw.y + 1,
      zfactor = Math.pow(2, zoom),
      minX = tileCoordinateNw.x,
      minY = tileCoordinateNw.y;

    while (tileRows--) {
      while (tileColumns--) {
        tiles.push({
          x: minX + tileColumns,
          y: minY,
          z: zoom,
        });
      }

      minY++;
      tileColumns = tileCoordinateSe.x - tileCoordinateNw.x + 1;
    }

    return tiles;
  }

  pointToTile(map, latLng, z) {
    var projection = map.getProjection();
    var worldCoordinate = projection.fromLatLngToPoint(latLng);
    var pixelCoordinate = new google.maps.Point(
      worldCoordinate.x * Math.pow(2, z),
      worldCoordinate.y * Math.pow(2, z)
    );
    var tileCoordinate = new google.maps.Point(
      Math.floor(pixelCoordinate.x / 256),
      Math.floor(pixelCoordinate.y / 256)
    );
    return tileCoordinate;
  }

  fromLatLngToPoint(latLng){
     var siny =  Math.min(Math.max(Math.sin(latLng.lat* (Math.PI / 180)),
                                   -.9999),
                          .9999);
     return {
      x: 128 + latLng.lng * (256/360),
      y: 128 + 0.5 * Math.log((1 + siny) / (1 - siny)) * -(256 / (2 * Math.PI))
     };
  }

  fromPointToLatLng(point){

     return {
      lat: (2 * Math.atan(Math.exp((point.y - 128) / -(256 / (2 * Math.PI)))) -
             Math.PI / 2)/ (Math.PI / 180),
      lng:  (point.x - 128) / (256 / 360)
     };

  }

  getTileAtLatLng(latLng,zoom){
    var t=Math.pow(2,zoom),
        s=256/t,
        p=this.fromLatLngToPoint(latLng);
        return {x:Math.floor(p.x/s),y:Math.floor(p.y/s),z:zoom};
  }

  getTileBounds(tile, isNeedToNormalize = false){
    if(isNeedToNormalize) {
      tile=this.normalizeTile(tile);
    }

    var t=Math.pow(2,tile.z),
        s=256/t,
        sw={x:tile.x*s,
            y:(tile.y*s)+s},
        ne={x:tile.x*s+s,
            y:(tile.y*s)};
        return{sw:this.fromPointToLatLng(sw),
               ne:this.fromPointToLatLng(ne)
              }
  }
  normalizeTile(tile){
    var t=Math.pow(2,tile.z);
    tile.x=((tile.x%t)+t)%t;
    tile.y=((tile.y%t)+t)%t;
    return tile;
  }

}
