import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DownloadService {

  constructor(private http: HttpClient) {}

  async download(url: string, params: any = null): Promise<Blob> {
    const file =  await this.http.get(url, {
      responseType: 'blob',
      params: params
    }).toPromise();
    return file;
  }
}
