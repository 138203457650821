import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-pkks-dublicate-popup',
  templateUrl: './pkks-dublicate-confirm-popup.component.html',
  styleUrls: ['./pkks-dublicate-confirm-popup.component.scss'],
})
export class PkksDublicatePopupComponent implements OnInit {
  @Output() confirmMoveEvent = new EventEmitter<boolean>();
  @Input() folderName: string;
  constructor() {}

  ngOnInit(): void {}

  confirm(): void {
    this.confirmMoveEvent.emit(true);
  }

  close(): void {
    this.confirmMoveEvent.emit(false);
  }
}
