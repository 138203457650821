<div class="item-moved" [class.active]="toaster">
  Номер {{KeyList.length > 135 ? KeyList.slice(0, 135) + '...' : KeyList}} перемещён в «{{toFilderName}}»
</div>
<div class="item-moved" [class.active]="copyToas">
  ссылка скопирована в буфер обмена
</div>
<div class="container">
  <div class="map">
    <div id="map" class="googlemap-wrapper" [class.mapWidth]="homeService.burgerOpen">
      <div class="divergence-popup " *ngIf="divergencePopup">
        <svg class="close-button link" (click)="divergencePopup = false" width="30" height="30" viewBox="0 0 30 30"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="3" fill="#F3F3F3"/>
          <path d="M10.0508 10.0503L19.9503 19.9498" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M10.0508 19.9497L19.9503 10.0502" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>

        <div class="title">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" stroke="#3163B2" stroke-width="1.5"/>
            <path d="M9.00049 8.1001V13.5001" stroke="#3163B2" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <circle cx="8.9999" cy="4.9501" r="1.00039" fill="#3163B2"/>
          </svg>
          Имеются расхождения в площади объектов
        </div>

        <div class="subtitle">
          <span> Площадь участка {{divergencePopup.cadItem.key}}</span>
          <span>{{divergencePopup.cadItem.areaValue | number:'':'fr-FR' || ''
            }}{{divergencePopup.cadItem.areaUnit.name}}</span>
        </div>

        <div class="subtitle">
          <span class="gray">Сумма площадей внутренних участков </span>
          <span>{{divergencePopup.cadItem.sumAreaValue | number:'':'fr-FR' ||
          ''}}{{divergencePopup.cadItem.areaUnit.name}}</span>
        </div>

        <div class="scroll-wrapper">
          <div class="divergencePopup-cհilds subtitle" *ngFor="let child of divergencePopup.childItems">
            <span>{{child.cadItem.key}}</span><span> {{child.cadItem.areaValue | number:'':'fr-FR' || '' }}
            {{divergencePopup.cadItem.areaUnit.name}}</span>
          </div>
        </div>
      </div>
      <a id="showScreenshotBut" class="save-map-picture" (click)="screenshot()">
        <svg class="save-icon" width="14" height="24" viewBox="0 0 14 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M7.00005 15.9992L7 1.15624" stroke="#3163B2" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M6.99998 15.9999L9.85713 13.1424L11.2857 11.7137L12 10.9993" stroke="#3163B2" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 16.0001L4.14285 13.1426L2.71427 11.7139L1.99998 10.9995" stroke="#3163B2" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1 23H13" stroke="#3163B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.5 19.5H7H10.5" stroke="#3163B2" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </a>
      <a id="copylinkBut" class="share-map-link" (click)="generateAccessToken()">
        <img class="share-icon" src="../../../assets/images/Vector.png" alt="icon">
      </a>

      <ng-container [ngSwitch]="currentMap">
        <google-map *ngSwitchCase="mapHost.Google"
                    #mymap height="90vh" width="100%"
                    (zoomChanged)="zoomChange()"
                    (tilesloaded)="onTileLoaded()"
                    [center]="center" [options]="options" [mapTypeId]="mapTypeId"
        >
          <map-marker *ngIf="currentPosition" [position]="currentPosition.position" [options]="currentPosition.options">
          </map-marker>
          <map-marker *ngFor="let pos of cadCenterPositions | filterSelected" #marker="mapMarker"
                      [position]="pos.position" [options]="pos.options" (mapClick)="openInfo(marker, pos.info.content)">
            <map-info-window [innerHTML]="cadInfoOnMap"></map-info-window>
          </map-marker>
        </google-map>

        <div *ngSwitchCase="mapHost.Yandex" id="yandexMap"></div>
        <div *ngSwitchCase="mapHost.OpenStreet" id="openStreetMap"></div>
      </ng-container>

      <div id='burgerOpenToggleButton' class="toggle-button" (click)="toggleBurger()">
        <svg width="4" height="22" viewBox="0 0 4 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="4" height="4" rx="2" fill="#BBBBBB"/>
          <rect y="9" width="4" height="4" rx="2" fill="#BBBBBB"/>
          <rect y="18" width="4" height="4" rx="2" fill="#BBBBBB"/>
        </svg>
      </div>

      <div class="geometry-info-block" *ngIf="geometryCalcs.area || geometryCalcs.distance || geometryCalcs.hectare">
        <div class="close-x" (click)="clearGeometryCalcs()">x</div>
        <span *ngIf="geometryCalcs.distance">Расстояние: {{ geometryCalcs.distance }} км</span>
        <span *ngIf="geometryCalcs.hectare">Площадь: {{ geometryCalcs.hectare }} га</span>
        <span *ngIf="geometryCalcs.area">Площадь: {{ geometryCalcs.area }} км²</span>
      </div>
    </div>
    <div class="left-bar" [class.toggle]="homeService.burgerOpen">
      <button class="link" [routerLink]="'/CadSearch'">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 7L12.9995 7.00015" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M0.999023 6.99991L4.99951 2.99989" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M5 11L0.999512 7.00002" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        Вернуться к вводу номеров
      </button>
      <div class="search">
        <input
          (keydown.enter)="searchCadNumber()"
          type="text"
          placeholder="Поиск по кадастровому номеру"
          class="input-search-file-result"
          [(ngModel)]="cadSearch">
        <svg (click)="searchCadNumber()" width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="10.5787" cy="10.5787" r="8.57873" stroke="#909090" stroke-width="1.5"/>
          <path d="M16.7752 16.7745L22.3631 22.3639" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
      <button class="blue-button" (click)="moveToFolder()">
        Переместить выбранное в папку
      </button>
      <p class="distance">
        {{scuare| number:'':'fr-FR' || '' }} кв. м
        <span>
          <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#909090"/>
          </svg>
        </span>
        {{selecdetPrice | number:'':'fr-FR' || '' }} ₽
      </p>
      <!--  function name renamed from showOnMap() to moveToElement()  -->
      <p (click)="moveToElement()" class="show-on-map link">
        Показать на карте
      </p>
      <ol>
        <p *ngIf="!cadItems.length">"По Вашему запросу ничего не найдено"</p>
        <li *ngFor="let item of cadItems;let i = index">
          <div class="details" [class.open]="item.open">
            <div class="summary">
              <div class="summary-wrapper">
                <div class="number-wrapper">
                  <p class="item-number">{{ i + 1}}</p>
                </div>
                <div class="info-text">
                  <p class="title" [style.background-color]="isAllChildLoaded(item)"> {{item.cadItem.key}}</p>

                  <div class="item-properties-wrapper">

                    <span class="" *ngIf="item.cadItem.pkkTagTypeId">
                      <span class="pkk-type" *ngFor="let type of tagTypes">
                        <span class="pkk-type-info" *ngIf="type.id == item.cadItem.pkkTagTypeId"
                              [ngClass]="{'green': type.id == 3, 'orange': type.id == 2, 'grey': type.id == 1,'hide': 0 }">
                          {{type.name}}</span>
                      </span>
                    </span>

                    <span class="without-border" *ngIf="item.cadItem.typeParent">{{item.cadItem.typeParent}}</span>
                    <span *ngIf="item.cadItem.colorAreaId" class="single-color in-map-component"
                          [style.border-color]="item.cadItem.colorAreaId ? colors[item.cadItem.colorAreaId-1].value : '#CE2727'">
                      <span class="fill"
                            [style.background-color]="item.cadItem.colorAreaId ? colors[item.cadItem.colorAreaId-1].value : '#CE2727'"></span>
                    </span>


                  </div>


                  <p class="subtitle">{{item.cadItem.address}}</p>
                </div>
                <div class="checkbox-wrapper">
                  <div class="squer-divergence-info" *ngIf="item['divergenceHover']">
                    <ng-container
                      *ngIf="(item.childItems.length && item.cadItem.areaValue !== item.cadItem.sumAreaValue)">
                      Имеются расхождения в площади объектов <span
                      (click)="divergencePopup = item;item.divergenceHover = false"
                      class="squer-divergence-info-button">Показать</span>
                    </ng-container>

                    <p *ngIf="(item.cadItem.imageBase64 && !item.cadItem.imageSvg)" class="danger-text">Границы могут
                      быть показаны некорректно</p>
                  </div>
                  <svg
                    *ngIf="(item.childItems.length && item.cadItem.areaValue !== item.cadItem.sumAreaValue) || (item.cadItem.imageBase64 && !item.cadItem.imageSvg)"
                    (click)="item.divergenceHover = !item.divergenceHover ?  true : false" class="squer-divergence"
                    width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" stroke="#DD6868" stroke-width="1.5"/>
                    <path d="M9.00049 8.1001V13.5001" stroke="#DD6868" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <circle cx="8.9999" cy="4.9501" r="1.00039" fill="#DD6868"/>
                  </svg>
                  <label class="checkbox-container">
                    <input type="checkbox"
                           (click)="handleItemCheckboxClicked(item, $event)"
                           [ngModel]="item.checked">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <!-- on click "Информация" add attribute open to details -->
              <!-- if opened hide "Информация" and add to the second link "opened" -->
              <div class="buttons-wrapper">
                <button class="link blue" *ngIf="!item.open"
                        (click)="handleOnClickGenInformation(item, false)">Информация
                </button>
                <button class="link blue" *ngIf="item.open "
                        (click)="handleOverlayHide(item, false)">Скрыть
                </button>
                <button class="link blue" (click)="moveToElement(item)">Показать на карте</button>
                <button class="link delete" (click)="removeOverlay(item);cadItems.splice(i,1);this.sumAmionth();">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.05078 2.05026L11.9503 11.9498" stroke="#909090" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.05078 11.9497L11.9503 2.05024" stroke="#909090" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Удалить
                </button>
              </div>
            </div>
            <div class="info" *ngIf="!item.showChilds ">
              <div class="info-text">
                <p class="info-name">Тип</p>
                <p class="info-subtitle">{{item.cadItem.type}}</p>
              </div>
              <div class="info-text">
                <p class="info-name">Кад.номер</p>
                <p class="info-subtitle"> {{item.cadItem.cadastralNumber}}</p>
              </div>
              <div class="info-text">
                <p class="info-name">Кад.квартал</p>
                <p class="info-subtitle"> {{item.cadItem.cadastralQuarter}}</p>
              </div>
              <div class="info-text " *ngIf="item.cadItem.status?.name">
                <p class="info-name">Статус</p>
                <p class="info-subtitle"> {{item.cadItem.status?.name}}</p>
              </div>
              <div class="info-text ">
                <p class="info-name">Кадастровая стоимость</p>
                <p class="info-subtitle"> {{item.cadItem.cadastralCost | number:'':'fr-FR' || '' }}
                  {{item.cadItem.cadUnit?.name}}</p>
              </div>
              <div class="info-text ">
                <p class="info-name">Общая площадь
                </p>
                <p class="info-subtitle">{{item.cadItem.areaValue | number:'':'fr-FR' || '' }}
                  {{item.cadItem.areaUnit.name}} </p>
              </div>
              <div class="info-text ">
                <p class="info-name">Адрес</p>
                <p class="info-subtitle">{{item.cadItem.address}} </p>
              </div>
              <div class="info-text" *ngIf="item.cadItem.shareInTheRight">
                <p class="info-name">Доля в праве</p>
                <span class="info-subtitle shareInTheRight"> <span>{{item.cadItem.shareInTheRight.split('/')[0]}}</span>
                  /
                  <span>{{item.cadItem.shareInTheRight.split('/')[1]}}</span> </span>

              </div>
              <div class="info-text " *ngIf="item.cadItem.categoryType?.name">
                <p class="info-name">Категория земель</p>
                <p class="info-subtitle"> {{item.cadItem.categoryType?.name}}</p>
              </div>
              <div class="info-text " *ngIf="item.cadItem.byDocument">
                <p class="info-name">По документу </p>
                <p class="info-subtitle"> {{item.cadItem.byDocument}}</p>
              </div>
              <div class="info-text " *ngIf="item.cadItem.permittedUse">
                <p class="info-name">Состав участков</p>
                <p class="info-subtitle"> {{item.cadItem.permittedUse}}</p>
              </div>
              <div class="info-text tree"
                   *ngIf="item.cadItem.typeParcel  == 'Единое землепользование' || item.cadItem.typeParcel  == 'Контуры МКЗУ'">
                <p class="info-name">Состав</p>
                <p class="info-subtitle" (click)="item.showChilds = true"> {{item.cadItem.typeParcel}}</p>
              </div>
            </div>
            <div class="info" *ngIf="item.showChilds">
              <div class="back-button" (click)="item.showChilds = false">
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5L12.9995 5.00015" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M0.999023 4.99989L4.99951 0.999878" stroke="#909090" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5 9.00005L0.999512 5.00003" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
                Назад
              </div>
              <div *ngFor="let child of item.childItems; let j = index">
                <div class="details" [class.open]="item.open">
                  <div class="summary">
                    <div class="summary-wrapper">
                      <div class="number-wrapper">
                        <p class="item-number">{{j + 1}}</p>
                      </div>
                      <div class="info-text">
                        <p class="title"
                           [style.color]="child.cadItem.downloadException ? 'rgba(255,0,0,1)' :
                            !child.cadItem.downloadException && !(child.cadItem.imageBase64 || child.cadItem.imageSvg) ? 'rgb(255,0,255)' : ''">
                          {{child.cadItem.key}}
                        </p>
                      </div>
                      <div class="checkbox-wrapper">
                        <label class="checkbox-container">
                          <input type="checkbox" [checked]="child.checked"
                                 (click)="parentCheched(item.cadItem.id,child,$event);toggleChildItem(child, $event)">
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="buttons-wrapper">
                      <button class="link blue" *ngIf="!child.open" (click)="handleOnClickGenInformation(child, true)">
                        Информация
                      </button>
                      <button class="link blue" *ngIf="child.open " (click)="handleOverlayHide(child, true)">
                        Скрыть
                      </button>
                      <button (click)="moveToElement(child)" class="link blue">
                        Показать
                      </button>
                      <button class="link delete"
                              (click)="child.overlay?.onRemove() ;item.childItems.splice(j,1);this.sumAmionth()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.05078 2.05026L11.9503 11.9498" stroke="#909090" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M2.05078 11.9497L11.9503 2.05024" stroke="#909090" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Удалить
                      </button>
                    </div>
                  </div>
                  <div class="info" *ngIf="child.open">
                    <div class="info-text">
                      <p class="info-name">Тип</p>
                      <p class="info-subtitle">{{child.cadItem.type}}</p>
                    </div>
                    <div class="info-text">
                      <p class="info-name">Кад.номер</p>
                      <p class="info-subtitle"> {{child.cadItem.cadastralNumber}}</p>
                    </div>
                    <div class="info-text">
                      <p class="info-name">Кад.квартал</p>
                      <p class="info-subtitle"> {{child.cadItem.cadastralQuarter}}</p>
                    </div>
                    <div class="info-text ">
                      <p class="info-name">Статус</p>
                      <p class="info-subtitle"> {{child.cadItem.status?.name}}</p>
                    </div>
                    <div class="info-text ">
                      <p class="info-name">Кадастровая стоимость</p>
                      <p class="info-subtitle"> {{child.cadItem.cadastralCost}} {{item.cadItem.cadUnit?.name}}</p>
                    </div>
                    <div class="info-text ">
                      <p class="info-name">Общая площадь
                      </p>
                      <p class="info-subtitle">{{child.cadItem.areaValue}} {{item.cadItem.areaUnit.name}}</p>
                    </div>
                    <div class="info-text ">
                      <p class="info-name">Адрес</p>
                      <p class="info-subtitle">{{child.cadItem.address}} </p>
                    </div>
                    <div class="info-text " *ngIf="child.cadItem.categoryType?.name">
                      <p class="info-name">Категория земель</p>
                      <p class="info-subtitle"> {{child.cadItem.categoryType?.name}}</p>
                    </div>
                    <div class="info-text " *ngIf="child.cadItem.byDocument">
                      <p class="info-name">По документу </p>
                      <p class="info-subtitle"> {{child.cadItem.byDocument}}</p>
                    </div>
                    <div class="info-text " *ngIf="child.cadItem.permittedUse">
                      <p class="info-name">Состав участков</p>
                      <p class="info-subtitle"> {{child.cadItem.permittedUse}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </div>
    <!-- right side tabs -->

    <div class="right-burger" (click)="rightBarOpen = !rightBarOpen">
      <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.99935 2.66667H25.9993C26.353 2.66667 26.6921 2.52619 26.9422 2.27614C27.1922 2.02609 27.3327 1.68696 27.3327 1.33333C27.3327 0.979711 27.1922 0.640573 26.9422 0.390525C26.6921 0.140476 26.353 0 25.9993 0H1.99935C1.64573 0 1.30659 0.140476 1.05654 0.390525C0.806491 0.640573 0.666016 0.979711 0.666016 1.33333C0.666016 1.68696 0.806491 2.02609 1.05654 2.27614C1.30659 2.52619 1.64573 2.66667 1.99935 2.66667ZM25.9993 13.3333H1.99935C1.64573 13.3333 1.30659 13.4738 1.05654 13.7239C0.806491 13.9739 0.666016 14.313 0.666016 14.6667C0.666016 15.0203 0.806491 15.3594 1.05654 15.6095C1.30659 15.8595 1.64573 16 1.99935 16H25.9993C26.353 16 26.6921 15.8595 26.9422 15.6095C27.1922 15.3594 27.3327 15.0203 27.3327 14.6667C27.3327 14.313 27.1922 13.9739 26.9422 13.7239C26.6921 13.4738 26.353 13.3333 25.9993 13.3333ZM25.9993 6.66667H1.99935C1.64573 6.66667 1.30659 6.80714 1.05654 7.05719C0.806491 7.30724 0.666016 7.64638 0.666016 8C0.666016 8.35362 0.806491 8.69276 1.05654 8.94281C1.30659 9.19286 1.64573 9.33333 1.99935 9.33333H25.9993C26.353 9.33333 26.6921 9.19286 26.9422 8.94281C27.1922 8.69276 27.3327 8.35362 27.3327 8C27.3327 7.64638 27.1922 7.30724 26.9422 7.05719C26.6921 6.80714 26.353 6.66667 25.9993 6.66667Z"
          fill="#222222"/>
      </svg>
    </div>

    <div class="location-button-wrapper" id="location-for-screenshot">
      <a id="asdasd" class="share-map-link location-button" (click)="goToCurrentLocation()">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28.4477 1.10595C28.1658 0.964684 27.8338 0.964684 27.5519 1.10595L1.55265 14.1055C1.05878 14.3527 0.858802 14.9534 1.10594 15.4473C1.24662 15.7284 1.51134 15.927 1.82059 15.9834L12.1393 17.8604L14.0162 28.179C14.093 28.602 14.4317 28.9286 14.8572 28.99C14.9042 28.9968 14.9517 29.0001 14.9992 29C15.3782 29.0002 15.7247 28.7861 15.8942 28.447L28.8939 2.44787C29.1412 1.95412 28.9415 1.35332 28.4477 1.10595ZM15.4462 24.8691L13.9822 16.8214C13.9065 16.415 13.5886 16.097 13.1823 16.0214L5.13057 14.5534L25.7629 4.23686L15.4462 24.8691Z"
            fill="#222222" stroke="white" stroke-width="0.5"/>
        </svg>
      </a>
    </div>

    <div class="right-bar" [class.right-bar-toggle]="rightBarOpen">
      <div class="right-bar-close" (click)="rightBarOpen = !rightBarOpen">
        <span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.94922 5.05029L14.8487 14.9498" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M4.94922 14.9497L14.8487 5.05021" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </span>
      </div>

      <div id="action-wrapper">
        <ng-select class="current-map-selector" [(ngModel)]="currentMap" [clearable]="false"
                   (ngModelChange)="mapChange()">
          <ng-option [value]="mapHost.Google">Google карты</ng-option>
          <ng-option [value]="mapHost.Yandex">Яндекс Карты</ng-option>
          <ng-option [value]="mapHost.OpenStreet">OpenStreetMap</ng-option>
        </ng-select>
        <div class="dropdown-body right-bar-tabs">
          <button class="bg-appears-button link " [class.active]="mapTypeId === 'roadmap' "
                  (click)="viewChange('roadmap','Схема')">Схема
          </button>
          <button class="bg-appears-button link" [class.active]="mapTypeId === 'satellite' "
                  (click)="viewChange('satellite','Спутник')">
            Спутник {{currentMap == mapHost.OpenStreet ? '(Bing)' : ''}}
          </button>
          <button class="bg-appears-button link" [class.active]="mapTypeId === 'hybrid' "
                  (click)="viewChange('hybrid','Гибрид')">Гибрид {{currentMap == mapHost.OpenStreet ? '(Bing)' : ''}}
          </button>
        </div>

        <div class="google-map-tools" *ngIf="currentMap == mapHost.Google">
          <div class="map-type toggle-border center-icon-toggle">
            <img class="red-map" src="../../../assets/images/target-circles.svg" alt="map">

            <label class="cl-switch">
              <input #centerMarkersCheckbox (click)='addCenterMarkers(centerMarkersCheckbox.checked)' [checked]="false"
                     type="checkbox">
              <span class="switcher"></span>

            </label>
          </div>
          <div class="map-type toggle-border center-icon-toggle-polyline">
            <svg class="red-map" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M3.501,21c-0.144,0-0.288-0.021-0.432-0.064c-0.794-0.238-1.244-1.074-1.006-1.868l3-10 c0.13-0.433,0.448-0.784,0.866-0.956c0.419-0.172,0.891-0.146,1.289,0.07l9.333,5.091l2.501-9.169 c0.218-0.799,1.045-1.27,1.842-1.053c0.8,0.218,1.271,1.042,1.053,1.842l-3,11c-0.121,0.443-0.438,0.806-0.859,0.985 c-0.423,0.181-0.903,0.156-1.307-0.063l-9.376-5.114l-2.469,8.229C4.742,20.581,4.146,21,3.501,21z"
                opacity=".35"></path>
              <circle cx="3.5" cy="19.5" r="2.5"></circle>
              <circle cx="6.5" cy="9.5" r="2.5"></circle>
              <circle cx="17.5" cy="15.5" r="2.5"></circle>
              <circle cx="20.5" cy="4.5" r="2.5"></circle>
            </svg>

            <label class="cl-switch">
              <input (click)='addPolyline($event)' [checked]="false"
                     type="checkbox">
              <span class="switcher"></span>

            </label>
          </div>
          <div class="map-layers">
            <h3 class="map-layers-title">Отображать слои:</h3>

            <!-- <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer1.png"/>
              <div class="map-layers-name">Единицы кадастрового деления</div>
            </div> -->

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox" #checkbox (click)="pkkLayersToggle(checkbox.checked, layerTypes.Land)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer2.png"/>
              <div class="map-layers-name">Земельные участки</div>
            </div>

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox" #buildingCheckbox
                       (click)="pkkLayersToggle(buildingCheckbox.checked, layerTypes.Building)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer3.png"/>
              <div class="map-layers-name">Здания сооружения,объекты незавершенного строительства</div>
            </div>

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox" #unionCheckbox
                       (click)="pkkLayersToggle(unionCheckbox.checked, layerTypes.Union)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer4.png"/>
              <div class="map-layers-name">Единые недвижимые комплексы</div>
            </div>

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox" #borderCheckbox
                       (click)="pkkLayersToggle(borderCheckbox.checked,layerTypes.Border)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer5.png"/>
              <div class="map-layers-name">Границы</div>
            </div>

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox" #specialCheckbox
                       (click)="pkkLayersToggle(specialCheckbox.checked,layerTypes.SpecialZone)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer6.png"/>
              <div class="map-layers-name">Зоны с особыми условиями использования территории</div>
            </div>

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input type="checkbox" #terCheckbox
                       (click)="pkkLayersToggle(terCheckbox.checked, layerTypes.Territory)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer7.png"/>
              <div class="map-layers-name">Территориальные зоны</div>
            </div>

            <div class="map-layers-item">
              <label class="checkbox-container">
                <input #zoneCheckbox type="checkbox" (click)="pkkLayersToggle(zoneCheckbox.checked, layerTypes.Zone)">
                <span class="checkmark"></span>
              </label>
              <img src="../../../assets/images/map-layer8.png"/>
              <div class="map-layers-name">Зоны и территории</div>
            </div>
          </div>
        </div>
        <div class="google-map-tools" *ngIf="currentMap == mapHost.Yandex">
          <div class="map-type toggle-border center-icon-toggle-polyline">
            <svg class="red-map" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M3.501,21c-0.144,0-0.288-0.021-0.432-0.064c-0.794-0.238-1.244-1.074-1.006-1.868l3-10 c0.13-0.433,0.448-0.784,0.866-0.956c0.419-0.172,0.891-0.146,1.289,0.07l9.333,5.091l2.501-9.169 c0.218-0.799,1.045-1.27,1.842-1.053c0.8,0.218,1.271,1.042,1.053,1.842l-3,11c-0.121,0.443-0.438,0.806-0.859,0.985 c-0.423,0.181-0.903,0.156-1.307-0.063l-9.376-5.114l-2.469,8.229C4.742,20.581,4.146,21,3.501,21z"
                opacity=".35"></path>
              <circle cx="3.5" cy="19.5" r="2.5"></circle>
              <circle cx="6.5" cy="9.5" r="2.5"></circle>
              <circle cx="17.5" cy="15.5" r="2.5"></circle>
              <circle cx="20.5" cy="4.5" r="2.5"></circle>
            </svg>

            <label class="cl-switch">
              <input (click)='addYandexPolyline($event)' [checked]="false" type="checkbox">
              <span class="switcher"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<section class="loading" *ngIf="showLoading">
  <p class="loading-status">Создание отчета... {{cad.countOfPkks}}/{{cad.allPkkCount}}</p>
  <div class="loading-img">
    <p class="percent loader"></p>
  </div>
</section>
<app-item-move-popup *ngIf="itemMovePopup" (closeEvent)="closePopup()" (MoveEvent)="onMoveEvent($event)">
</app-item-move-popup>
<app-create-folder *ngIf="createFolderPopup" (closepopup)="createFolderClose($event) "
                   (MoveEvent)="onMoveEvent($event)"></app-create-folder>
<app-pkks-dublicate-popup *ngIf="showDublicatePopup" [folderName]="toFilderName"
                          (confirmMoveEvent)="confirmMove($event)">
</app-pkks-dublicate-popup>
<input hidden #myInput type="text">
